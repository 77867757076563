import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  deferredPrompt: Event | null;
  visible: boolean;
} = {
  deferredPrompt: null,
  visible: false,
};

const download = createSlice({
  name: "download-prompt",
  initialState,
  reducers: {
    setDownloadPrompt(state, action: PayloadAction<Event | null>) {
      state.deferredPrompt = action.payload;
    },
    setVisible(state, action: PayloadAction<any>) {
      state.visible = action.payload;
    },
    reset: () => initialState,
  },
});

export const downloadActions = download.actions;
export const downloadReducer = download.reducer;
