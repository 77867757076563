// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FleetManagementEstimate,
  initialState,
} from "../models/onboarding/fleetManagement.model";

export type TVehicleAmount = {
  id: number;
  text: string;
  value?: string;
};

export type ManagementMethod = {
  isSelected: boolean;
  title: string;
  value: string;
};

export type FleetContact = {
  name: string;
  email: string;
  phone: string;
};
export interface IFleetManagement {
  vehicleAmount: TVehicleAmount;
  maintenanceCost: string;
  vehicleTracking: TVehicleAmount;
  processDescription: string;
  deviceBrand: TVehicleAmount;
  managementMethod: ManagementMethod[];
  fleetContact?: FleetContact;
  estimate: FleetManagementEstimate | null;
  trackRiderMaintnance: {
    maintenance: TVehicleAmount;
    maintenanceOptions: TVehicleAmount[];
    challenges: ManagementMethod[];
  };
  partnershipMaintnance: {
    manageSchedule: TVehicleAmount;
    manageScheduleOptions: TVehicleAmount[];
    improvements: ManagementMethod[];
  };
}

const fleetManagement = createSlice({
  name: "fleetManagement",
  initialState,
  reducers: {
    setVehicleAmount(state, action: PayloadAction<TVehicleAmount>) {
      state.vehicleAmount = { ...action.payload };
    },
    setMaintenanceCost(state, action: PayloadAction<string>) {
      state.maintenanceCost = action.payload;
    },
    setVehicleTracking(state, action: PayloadAction<TVehicleAmount>) {
      state.vehicleTracking = { ...action.payload };
    },
    setProcessDescription(state, action: PayloadAction<string>) {
      state.processDescription = action.payload;
    },
    setDeviceBrand(state, action: PayloadAction<TVehicleAmount>) {
      state.deviceBrand = { ...action.payload };
    },
    setManagementMethod(state, action: PayloadAction<ManagementMethod[]>) {
      state.managementMethod = action.payload;
    },
    setEstimate(state, action: PayloadAction<FleetManagementEstimate>) {
      state.estimate = action.payload;
    },
    setContact(state, action: PayloadAction<FleetContact>) {
      state.fleetContact = action.payload;
    },
    setTrackingMaintenance(state, action: PayloadAction<any>) {
      state.trackRiderMaintnance = action.payload;
    },
    setPartnershipMaintenance(state, action: PayloadAction<any>) {
      state.partnershipMaintnance = action.payload;
    },
    reset: () => initialState,
  },
});

export const fleetManagementActions = fleetManagement.actions;
export const fleetMangementReducer = fleetManagement.reducer;
