import { Component, ErrorInfo, ReactNode } from "react";
import errorImage from "../../assets/images/error boundary.png";
import EmptyState from "../EmptyState/EmptyState";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: "100dvh" }}>
          <EmptyState
            icon={errorImage}
            imageSize="200px"
            mainText="Sorry.. There was an error"
            subText="We're not exactly sure what happened but something went wrong. Either refresh the page or return home to try again"
            actionText="Try Again"
            action={() => window.location.reload()}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
