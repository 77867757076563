// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OnboardingflowType } from "../models/onboarding/onboardingData";
import { CustomerProductivityEstimate } from "../models/onboarding/customerProductivity.model";

interface CustomerProductivityInterface {
  satisfactionLevel: string;
  riderOption: string;
  averageTime: { time: number; timeType: string };
  averageDelivery: number;
  estimate: CustomerProductivityEstimate | null;
  onboardingFlowType?: OnboardingflowType;
  isTyping: boolean;
}

const initialState: CustomerProductivityInterface = {
  satisfactionLevel: "",
  riderOption: "",
  averageTime: { time: 0, timeType: "" },
  averageDelivery: 0,
  estimate: null,
  isTyping: false,
};

const customerProductivity = createSlice({
  name: "Customer Productivity",
  initialState,
  reducers: {
    setSatisfactionLevel(state, action: PayloadAction<string>) {
      state.satisfactionLevel = action.payload;
    },
    setRiderOptions(state, action: PayloadAction<string>) {
      state.riderOption = action.payload;
    },
    setAverageTime(
      state,
      action: PayloadAction<{ time: number; timeType: string }>
    ) {
      state.averageTime = action.payload;
    },
    setAverageDelivery(state, action: PayloadAction<number>) {
      state.averageDelivery = action.payload;
    },
    setEstimate(state, action: PayloadAction<CustomerProductivityEstimate>) {
      state.estimate = action.payload;
    },
    setIsTyping(state, action: PayloadAction<boolean>) {
      state.isTyping = action.payload;
    },
    setOnboardingFlowType(
      state,
      action: PayloadAction<OnboardingflowType | undefined>
    ) {
      state.onboardingFlowType = action.payload;
    },
    reset: () => initialState,
  },
});

export const customerProductivityActions = customerProductivity.actions;
export const customerProductivityReducer = customerProductivity.reducer;
