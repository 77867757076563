export interface ProductGuideType {
  show_guide: boolean;
  started_guide: boolean;
  guide_steps: ProductGuideStep[];
  completed_guide: boolean;
}

export interface ProductGuideStep {
  step: number;
  heading: string;
  description: string;
  is_completed: boolean;
}

export interface ProductGuideStepType extends ProductGuideStep {
  icon?: string;
  navigate?: () => void;
  buttonText?: string;
  buttonClass?: string;
}

export const guideSteps = [
  {
    step: 1,
    heading: "Add Job",
    description:
      "Add your first delivery job manually or automatically by linking Motions to a chatbot or your job api",
    is_completed: false,
  },
  {
    step: 2,
    heading: "Add Rider",
    description:
      "Add your riders to enable automatic job assignment. Once riders set up Motions on their phones, they'll receive assigned jobs automatically.",
    is_completed: false,
  },
  {
    step: 3,
    heading: "Update Settings",
    description: "Set-up your preferences and key actions for your account.",
    is_completed: false,
  },
  {
    step: 4,
    heading: "My Deliveries",
    description:
      "Track and view all on-going deliveries with dispatcher currently on route.",
    is_completed: false,
  },
  {
    step: 5,
    heading: "Dashboard",
    description:
      "You did it! Get a full view of all your operations at a glance here: Completed deliveries, cancelled deliveries, income and more.",
    is_completed: false,
  },
];
