import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Libraries,
  MarkerF,
  PolygonF,
} from "@react-google-maps/api";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { icons } from "../../assets";
import { RidersType } from "../../models/riders";
import Mapstyles from "../../utilities/mapStyles";
import CustomMarker from "./CustomMarker";
import classes from "./mapDisplay.module.scss";
import { PolygonData } from "../../data";
import EmptyState from "../EmptyState/EmptyState";
import errorImage from "../../assets/images/error boundary.png";
import { convertCoordToAddress } from "@/helpers";
import { toast } from "react-toastify";

export const libraries: Libraries = ["places", "geometry"];

export interface PlacesList {
  title: string;
  position: LatLngType;
  icon?: string;
  className?: string;
  key: string;
}

//TODO: Switch to new react google maps package @vis.gl/react-google-maps

const options = {
  styles: Mapstyles,
  disableDefaultUI: true,
  zoomControl: false,
};

export interface RouteType {
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number } | undefined;
  rider: RidersType;
}

export interface LatLngType {
  lat: number;
  lng: number;
}

interface MapProps {
  route?: RouteType | null;
  onClose?: () => void;
  showInput?: boolean;
  clickable?: boolean;
  onHideMap?: () => void;
  setAddressResults?: (value: google.maps.GeocoderResult[] | null) => void;
  position?: LatLngType | null;
  setposition?: (data: LatLngType | null) => void;
  positionIcon?: string;
  ridersList?: RidersType[];
  locations?: PlacesList[];
  polygon?: PolygonData;
  zoom?: number;
  mapOptions?: google.maps.MapOptions;
  center?: LatLngType;
}

export const handleGetUserLocationError = (error: GeolocationPositionError) => {
  // Display error based on the error code.
  const { code } = error;
  switch (code) {
    case GeolocationPositionError.TIMEOUT:
      toast.warning("Timed out");
      break;
    case GeolocationPositionError.PERMISSION_DENIED:
      toast.warning("Permision Denied");
      break;
  }
};

const MapDisplay: FC<MapProps> = ({
  route,
  showInput,
  clickable = false,
  setAddressResults,
  position,
  onClose,
  onHideMap,
  setposition,
  positionIcon,
  center,
  polygon,
  zoom = 15,
  mapOptions,
  locations,
}) => {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: import.meta.env.REACT_APP_GOOGLEMAP_KEY,
  //   libraries,
  // });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState(
    center ?? { lat: 6.465422, lng: 3.406448 },
  );
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);

  const onLoad = useCallback((instance: google.maps.Map) => {
    setMap(instance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleSelectLocationFromMap = async (
    event: google.maps.MapMouseEvent,
  ) => {
    const latLng = event.latLng;

    if (!clickable || !latLng) return;

    if (setposition)
      setposition({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });

    const results = await convertCoordToAddress({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });

    if (!results.length) return;

    if (setAddressResults) setAddressResults(results);
    if (inputRef.current) inputRef.current.value = results[0].formatted_address;
  };

  const handleAddressChangedFromInput = async () => {
    if (inputRef.current?.value === "") return;

    const geocoder = new google.maps.Geocoder();

    const { results } = await geocoder.geocode({
      address: inputRef.current?.value,
    });

    if (!results.length) {
      alert("Address not found, Enter a valid address");
      return;
    }

    if (setAddressResults) setAddressResults(results);
    if (setposition)
      setposition({
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      });
  };

  useEffect(() => {
    if (polygon) {
      setMapCenter(polygon.center);
    }

    // if (!position && !route)
    //   navigator.geolocation.getCurrentPosition(
    //     onGetUserLocationSuccess,
    //     handleGetUserLocationError,
    //   );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map && position) {
      map.panTo(position);
    }
  }, [position, map]);

  useEffect(() => {
    const calculateRoute = async () => {
      if (!route || !route.destination) return;
      const directionService = new google.maps.DirectionsService();
      const result = await directionService.route({
        origin: route.origin,
        destination: route.destination,
        travelMode: google.maps.TravelMode.DRIVING,
      });

      setDirections(result);
    };

    if (route) calculateRoute();
  }, [route]);

  if (!window.google)
    return (
      <div style={{ height: "100dvh" }}>
        <EmptyState
          icon={errorImage}
          imageSize="200px"
          mainText="Failed to load Maps"
          subText="Sorry, we're having difficulty loading maps at the moment. Either refresh the page or return home to try again"
          actionText="Try Again"
          action={() => window.location.reload()}
        />
      </div>
    );

  return (
    <div className={classes.mapArea}>
      {showInput && (
        <div className={classes.inputContainer}>
          <span onClick={onHideMap}>
            <img src={icons.backShort} alt="" />
          </span>
          <Autocomplete onPlaceChanged={handleAddressChangedFromInput}>
            <input type="text" ref={inputRef} />
          </Autocomplete>
          <span onClick={onClose}>
            <img src={icons.inputClose} alt="" />
          </span>
        </div>
      )}

      <GoogleMap
        zoom={zoom}
        options={{ ...options, ...mapOptions }}
        center={mapCenter}
        mapContainerClassName={classes.map}
        onClick={handleSelectLocationFromMap}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {!polygon ? (
          <>
            {position && !route && (
              <>
                {positionIcon ? (
                  <CustomMarker
                    position={position}
                    icon={positionIcon}
                    classname={classes.riderMarker}
                  />
                ) : (
                  <MarkerF
                    position={position}
                    zIndex={1000000}
                    draggable={clickable}
                    onDragEnd={handleSelectLocationFromMap}
                    icon={icons.locationMarker}
                    animation={google.maps.Animation.BOUNCE}
                  />
                )}
              </>
            )}
            {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "black",
                  },
                }}
              />
            )}
            {route && (
              <>
                <CustomMarker
                  position={route.origin}
                  title={route.rider.name}
                  icon={route.rider.image}
                  classname={classes.riderMarker}
                />

                {route.destination && (
                  <MarkerF
                    position={route.destination}
                    zIndex={1000000}
                    icon={icons.destinationMarker}
                    animation={google.maps.Animation.BOUNCE}
                  />
                )}
              </>
            )}
            {locations && (
              <>
                {locations.map((location) => {
                  return (
                    <CustomMarker
                      position={{
                        lat: location.position.lat,
                        lng: location.position.lng,
                      }}
                      title={location.title}
                      icon={location.icon ?? icons.locationMarker}
                      classname={location.className ?? classes.riderMarker}
                      key={location.key}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <PolygonF
            path={polygon.coords}
            options={{
              strokeWeight: 0,
              fillColor: "#D77979",
              fillOpacity: 0.7,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default MapDisplay;
