import axios, { AxiosError } from "axios";
import { authActions } from "../redux/authSlice";
import store from "../redux/store";

const errorInterceptor = () =>
  axios.interceptors.response.use(
    (response) => response,
    async (err: AxiosError) => {
      if (err.status === "401" && location.href.includes("dashboard")) {
        store.dispatch(authActions.reset());
      }
      return Promise.reject(err);
    }
  );

export default errorInterceptor;
