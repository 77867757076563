/* eslint-disable import/named */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UploadJobState } from "../models/jobs";

const initialState: UploadJobState = {
  isBulkUpload: false,
  bulkUploadSuccessFull: false,
  startBulkUpload: false,
  uploadErrorMessage: null,
  uploadSuccessMessage: null,
};

const jobUploadSLice = createSlice({
  name: "job-upload",
  initialState,
  reducers: {
    setIsBulkUpload(state, action: PayloadAction<boolean>) {
      state.isBulkUpload = action.payload;
    },
    setBulkkUploadSuccess(state, action: PayloadAction<boolean>) {
      state.bulkUploadSuccessFull = action.payload;
    },
    setStartBulkUpload(state, action: PayloadAction<boolean>) {
      state.startBulkUpload = action.payload;
    },
    setUploadErrorMessage(state, action: PayloadAction<string | null>) {
      state.uploadErrorMessage = action.payload;
    },
    setUploadSuccessMessage(state, action: PayloadAction<string | null>) {
      state.uploadSuccessMessage = action.payload;
    },
  },
});

export const jobSliceActions = jobUploadSLice.actions;
export const jobUploadReducers = jobUploadSLice.reducer;
