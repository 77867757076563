// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PricingModel,
  PricingZones,
} from "../../models/dashboadSettings/pricingModel";

interface PricingManagement {
  model: PricingModel | null;
  zones: PricingZones | null;
  serviceareapoints: { start: string; end: string };
  type: "fixed" | "priority";
  serviceareaprice: {
    same_day: number;
    next_day: number;
    twoThreeDays: number;
  };
  serviceAreaBulkUploadState: {
    uploadErrorMessage: string | null;
    uploadSuccessMessage: string | null;
  };
}

export type ServiceAreaPriceFields = "same_day" | "next_day" | "twoThreeDays";
export type FormulabasedPriceFields =
  | "per_kg"
  | "per_minutes"
  | "per_km"
  | "base_rate";

const initialState: PricingManagement = {
  model: null,
  zones: null,
  serviceareapoints: { start: "", end: "" },
  type: "fixed",
  serviceareaprice: {
    same_day: 1500,
    next_day: 1500,
    twoThreeDays: 1500,
  },
  serviceAreaBulkUploadState: {
    uploadErrorMessage: null,
    uploadSuccessMessage: null,
  },
};

const pricingManagement = createSlice({
  name: "pricingManagement",
  initialState,
  reducers: {
    setModel(state, action: PayloadAction<PricingModel | null>) {
      state.model = action.payload;
    },
    setZones(state, action: PayloadAction<any>) {
      state.zones = action.payload;
    },
    setPoints(
      state,
      action: PayloadAction<{ arg: string; point: "start" | "end" }>
    ) {
      const { arg, point } = action.payload;
      state.serviceareapoints[point] = arg;
    },
    setServiceAreaPrice(
      state,
      action: PayloadAction<{
        price: number;
        field: ServiceAreaPriceFields;
      }>
    ) {
      const { price, field } = action.payload;
      state.serviceareaprice[field] = price;
    },
    setPricingType(state, action: PayloadAction<"fixed" | "priority">) {
      state.type = action.payload;
    },
    setFixedPrice(state, action: PayloadAction<number>) {
      state.serviceareaprice.next_day = action.payload;
      state.serviceareaprice.same_day = action.payload;
      state.serviceareaprice.twoThreeDays = action.payload;
    },
    setServiceAreaUpload(
      state,
      action: PayloadAction<{
        value: "uploadErrorMessage" | "uploadSuccessMessage";
        message: string;
      }>
    ) {
      const { value, message } = action.payload;

      state.serviceAreaBulkUploadState[value] = message;
    },
    resetPriceAndPoints(state) {
      state.serviceareapoints = initialState.serviceareapoints;
      state.serviceareaprice = initialState.serviceareaprice;
    },
    reset: () => initialState,
  },
});

export const pricingManagementActions = pricingManagement.actions;
export const pricingManagementReducer = pricingManagement.reducer;
