import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { motionsLogos } from "../../assets";
import hamburger from "../../assets/images/hamburgerIcon.png";
import BackgroundVideo from "../../components/BackgroundVideo/BackgroundVideo";
import Button from "../../components/Button/Button";
import classes from "./onboarding.module.scss";

const Intro = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);

  const year = new Date().getFullYear();

  return (
    <AnimatePresence>
      <Container>
        {showNav && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={classes.navOpen}
          >
            <div className={classes.content}>
              <img src={motionsLogos.logoWhite} alt="" />

              <button
                className={classes.close}
                onClick={() => setShowNav(!showNav)}
              >
                <AiOutlineCloseCircle />
              </button>
              <div className={classes.navOpen__buttons}>
                <Button
                  text="Log in"
                  className={classes.login}
                  type="button"
                  onClick={() => navigate(`/login`)}
                />
                <Button
                  text="Try now"
                  className={classes.nextButton}
                  type="button"
                  onClick={() => navigate(`welcome`)}
                />
              </div>
            </div>
          </motion.div>
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <>
            <BackgroundVideo />
            <div
              className={`${classes.pageContainer} d-flex flex-column text-white`}
            >
              <div className={classes.logoBox}>
                <img src={motionsLogos.logoWhite} alt="" />

                <div className={classes.navClose}>
                  <Button
                    text="Log in"
                    className={classes.login}
                    type="button"
                    onClick={() => navigate(`login`)}
                  />
                  <Button
                    text="Try now"
                    className={classes.nextButton}
                    type="button"
                    onClick={() => navigate(`welcome`)}
                  />

                  <button
                    className={classes.hamburger}
                    onClick={() => setShowNav(!showNav)}
                  >
                    <img src={hamburger} alt="" />
                  </button>
                </div>
              </div>
              <motion.div
                initial={{ opacity: 0, x: "100px" }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 1,
                }}
                exit={{ opacity: 0 }}
                style={{ marginTop: "30vh", maxWidth: "500px" }}
              >
                <h3 className="fw-bolder">
                  Unlock the full potential of your logistics operations{" "}
                </h3>
                <p>
                  Power-up growth, streamline and automate deliveries, lower
                  fuel costs, track your assets, and more.
                  <br />
                  <br />
                  All on one platform.
                </p>

                <Button
                  text="Try now"
                  className={classes.nextButton}
                  type="button"
                  onClick={() => navigate(`welcome`)}
                />
              </motion.div>
              <div className="mt-auto d-flex justify-content-between align-items-center fw-bold">
                <small>Motionspace Technologies llc © {year} </small>
                <span
                  className="d-flex gap-2 align-items-center"
                  style={{ opacity: 0.64 }}
                >
                  <FaTwitter />
                  <FaFacebookF />
                  <FaInstagram />
                  <FaLinkedin />
                </span>
              </div>
            </div>
          </>
        </motion.div>
      </Container>
    </AnimatePresence>
  );
};

export default Intro;
