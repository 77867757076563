import React, { useEffect, useState } from "react";
import classes from "./loader.module.scss";
import { icons } from "../../assets";
import { motion } from "framer-motion";

interface Props {
  component?: boolean;
}

const Loader = ({ component = false }: Props) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowText(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div
      className={component ? classes.loaderComponent : classes.loaderContainer}
    >
      <div className={classes.loader}>
        <div className={classes.loader_inner}>
          <img src={icons.motionsLogo} alt="" />
          <div></div>
        </div>
      </div>
      {showText && (
        <motion.p
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
        >
          Please wait a moment...
        </motion.p>
      )}
    </div>
  );
};

export default Loader;
