import { SuccessHttpResponse } from "@/models/https";
import { apiSlice } from "./apiSlice";
import { RidersType } from "@/models/riders";
import { RbUser } from "@/models/dashboadSettings/userManagement";
import { AuthResponse } from "@/models/auth";
import { RbUserCompleteReg } from "@/views/signUp/VerifyRbUser";
import { SetAdminRole } from "@/views/dashboard/Settings/UserManagement/components/RoleSelectItem";

export const userManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourierStaff: builder.query({
      async queryFn(_, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const rbUsers = await fetchWithBQ({
            url: "/auth-service/rbusers/list/",
            method: "GET",
          });
          const riders = await fetchWithBQ({
            url: "/rider-service/riders",
            method: "GET",
          });

          const ridersData = riders as {
            data: SuccessHttpResponse<RidersType[]>;
            meta: [];
          };
          const subUsersData = rbUsers as {
            data: SuccessHttpResponse<RbUser[]>;
            meta: [];
          };

          const allUsers: {
            name: string;
            id: string;
            type: string;
            image?: string;
          }[] = [
            ...ridersData.data.data.map((rider) => ({
              name: rider.name,
              id: rider.rider_id,
              type: "rider",
              image: rider.image,
            })),
            ...subUsersData.data.data.map((user) => ({
              name: `${user.first_name} ${user.last_name}`,
              id: user.id,
              type: "sub-user",
            })),
          ];

          // Combine the data and return as a single result
          return {
            data: allUsers,
          };
        } catch (err: any) {
          return {
            error: err,
          };
        }
      },
    }),
    getLoggedInRbUserRoles: builder.query<any, string>({
      query: (id) => ({
        url: `/auth-service/rbuser/permissions/${id}`,
        method: "GET",
      }),
      transformResponse: (res: SuccessHttpResponse<any>) => res.data,
    }),
    getAllRoleBasedUsers: builder.query<RbUser[], string>({
      query: () => ({
        url: "/auth-service/rbusers/list/",
        method: "GET",
      }),
      providesTags: ["Role-Based-Users"],
      transformResponse: (res: SuccessHttpResponse<RbUser[]>) => res.data,
    }),
    createNewRoleBasedUser: builder.mutation<any, Partial<RbUser>>({
      query: (data) => ({
        url: "/auth-service/rbuser/",
        method: "POST",
        data,
      }),
      invalidatesTags: ["Role-Based-Users"],
    }),
    resendRbUserInvitation: builder.mutation<any, Partial<RbUser>>({
      query: (data) => ({
        url: "/auth-service/rbuser/resend-verify-link/",
        method: "POST",
        data,
      }),
    }),
    completeRbUserSignUp: builder.mutation<AuthResponse, RbUserCompleteReg>({
      query: (data) => ({
        url: "/auth-service/rbuser/complete-reg/",
        method: "POST",
        data,
      }),
      transformResponse: (res: SuccessHttpResponse<AuthResponse>) => res.data,
    }),
    getAllAvailableUserRoles: builder.query<any[], undefined>({
      query: () => ({
        url: "/auth-service/rbuser/roles/",
        method: "GET",
      }),
      transformResponse: (res: SuccessHttpResponse<any>) => res.data,
    }),
    getDefaultPermissionsForSingleRole: builder.query<any, string>({
      query: (role) => ({
        url: `/auth-service/rbuser/default-permissions/?role=${role}`,
        method: "get",
      }),
      transformResponse: (res: SuccessHttpResponse<any>) => res.data,
    }),
    updateSingleRbUserPermissions: builder.mutation<
      any,
      { data: SetAdminRole; currentRole: string | null }
    >({
      query: ({ data, currentRole }) => ({
        url: `/auth-service/rbuser/permissions/`,
        method: currentRole ? "PUT" : "POST",
        data,
      }),
      transformResponse: (res: SuccessHttpResponse<any>) => res.data,
    }),
    deleteRoleBasedUser: builder.mutation<any, Partial<RbUser>>({
      query: (data) => ({
        url: "/auth-service/rbuser/",
        method: "DELETE",
        data,
      }),
      invalidatesTags: ["Role-Based-Users"],
    }),
  }),
});

export const {
  useGetAllCourierStaffQuery,
  useGetLoggedInRbUserRolesQuery,
  useGetAllRoleBasedUsersQuery,
} = userManagementApiSlice;
