import axios from "axios";
import store from "../redux/store";

const authInterceptor = () =>
  axios.interceptors.request.use(
    (request) => {
      const access_token = store.getState().auth.access_token;

      if (access_token && request.headers) {
        request.headers.Authorization = `Bearer ${access_token}`;
      }

      return request;
    },
    (error) => Promise.reject(error),
  );

export default authInterceptor;
