import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import store from "../redux/store";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://dev-api.motions.space",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const access_token = store.getState().auth.access_token;

    if (access_token && config.headers) {
      config.headers.Authorization = `bearer ${access_token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err: AxiosError) => {
    if (err.status === "401" && location.href.includes("dashboard")) {
      store.dispatch({ type: "RESET" });
    }
    return Promise.reject(err);
  },
);

export default axiosInstance;
