// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RbUser } from "../../models/dashboadSettings/userManagement";

const initialState: { users: RbUser[] } = {
  users: [],
};

const userManagement = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<RbUser[]>) {
      state.users = action.payload;
    },
    reset: () => initialState,
  },
});

export const userManagementActions = userManagement.actions;
export const userManagementReducer = userManagement.reducer;
