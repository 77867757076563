import RouteDom, { VendorApp } from "../routes";

export const APPS = [
  {
    subDomain: "dash",
    app: RouteDom,
    main: true,
  },
  {
    subDomain: "vendor",
    app: VendorApp,
    main: false,
  },
];
