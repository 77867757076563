import { authReducer } from "./authSlice";
import { waitingListReducer } from "./waitingListSlice";
import { newJobReducer } from "./newJobSlice";
import { jobUploadReducers } from "./jobUploadSlice";
import { jobRetriverReducer } from "./jobsRetriverSlice";
import { improveTimeReducer } from "./improveTimeSlice";
import { CustomerSatisfactionReducer } from "./customerSatisfactionSlice";
import { financialPerformanceReducer } from "./financialPerformanceSlice";
import { fleetMangementReducer } from "./fleetManagementSlice";
import { customerProductivityReducer } from "./customerProductivitySlice";
import { productGuideReducer } from "./productGuideSlice";
import { ridersListReducer } from "./ridersSlice";
import { walletReducer } from "./Settings/walletSlice";
import { userManagementReducer } from "./Settings/userManagementSlice";
import { pricingManagementReducer } from "./Settings/pricingManagementSlice";
import { ticketsSliceReducer } from "./ticketsSlice";
import { downloadReducer } from "./downloadPromptSlice";
import { vendorAuthReducer } from "./Vendor/vendorSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "@/services/apiSlice";
import { notificationSliceReducer } from "./notificationsSlice";

export const reducers = combineReducers({
  waiting: waitingListReducer,
  auth: authReducer,
  newJob: newJobReducer,
  jobUpload: jobUploadReducers,
  jobRetriver: jobRetriverReducer,
  improveTimes: improveTimeReducer,
  customerSatisfaction: CustomerSatisfactionReducer,
  financialPerformance: financialPerformanceReducer,
  fleetManagement: fleetMangementReducer,
  customerProductivity: customerProductivityReducer,
  productGuide: productGuideReducer,
  ridersList: ridersListReducer,
  wallet: walletReducer,
  userManagement: userManagementReducer,
  pricingModel: pricingManagementReducer,
  tickets: ticketsSliceReducer,
  notifications: notificationSliceReducer,
  download: downloadReducer,
  vendorAuth: vendorAuthReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = {};
  }
  return reducers(state, action);
};
