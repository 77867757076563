import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "../components/PageLoader/Loader";
import PaymentSuccessful from "../components/SuccessPages/ManualJob/PaymentSuccessful";
import { default as AuthRetrieveRoutes } from "./MainAppRoutes";
import {
  FORGOTPASSWORD,
  NONADMINSIGNUP,
  ONBOARDING,
  RESETPASSWORD,
  SCHEDULE,
  SCHEDULEDEMO,
  SIGNIN,
  SIGNUP,
  VERIFICATIONPROCESS,
  VERIFYEMAIL,
} from "./routeConstants";
import Welcome from "../views/onboarding/Welcome";

const Vendor = lazy(() => import("../views/VendorApp/VendorApp"));

const LockerSignUp = lazy(() => import("../views/Locker/Auth/LockerAuth"));

const VerifyEmail = lazy(() => import("../views/signUp/VerifyEmail"));
const VerificationProcess = lazy(
  () => import("../views/signUp/VerificationProcess"),
);
const Verified = lazy(() => import("../views/signUp/Verified"));
const ChatbotSchedule = lazy(
  () => import("../views/ChatbotSchedule/ChatbotSchedule"),
);
const ChatbotJobSuccess = lazy(
  () => import("../components/SuccessPages/ManualJob/ChatbotJobSuccess"),
);
const UserTrackPackage = lazy(
  () => import("../views/dashboard/deliveries/TrackPackage/UserTrackPackage"),
);
const VerifyRbUser = lazy(() => import("../views/signUp/VerifyRbUser"));
const ResetPassword = lazy(() => import("../views/signIn/ResetPassword"));
const ContactSales = lazy(
  () => import("../views/dashboard/Settings/Plans/ContactSales"),
);
const Enterprise = lazy(
  () => import("../views/dashboard/Settings/Plans/EnterprisePlanSubscribe"),
);

const Onboarding = lazy(() => import("../views/onboarding/Onboarding"));

const ForgotPassword = lazy(() => import("../views/signIn/ForgotPassword"));
const ScheduleDemo = lazy(
  () => import("../views/onboarding/ScheduleDemo/ScheduleDemo"),
);

const SignUp = lazy(() => import("../views/signUp/SignUp"));

const RouteDom = () => {
  return (
    <div style={{ height: "100%" }}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/*" element={<AuthRetrieveRoutes />} />
          <Route path={`/${VERIFYEMAIL}`} element={<VerifyEmail />} />
          <Route
            path={`/${VERIFICATIONPROCESS}`}
            element={<VerificationProcess />}
          />
          <Route path={`/${NONADMINSIGNUP}`} element={<VerifyRbUser />} />
          <Route path={`/${RESETPASSWORD}`} element={<ResetPassword />} />
          <Route path="/verified" element={<Verified />} />
          <Route path={`/${SCHEDULE}/:id`} element={<ChatbotSchedule />} />
          <Route
            path={`/payment/successful`}
            element={
              <PaymentSuccessful
                header="Payment Successful"
                subtext="Awesome! Your payment has been recieved and your transaction is being processed. Close this pop up to continue."
              />
            }
          />
          <Route path={`/sms`} element={<Enterprise />} />
          <Route
            path={`/sms/successful`}
            element={
              <PaymentSuccessful
                header="Full Steam Ahead! 🚀"
                subtext="Fantastic choice! Your payment has been recieved and You've just set your logistics in motion with
              an Enterprise plan"
                redirectUrl={`/${SIGNIN}`}
                buttonText="Continue to Login"
              />
            }
          />
          <Route
            path={`/${SCHEDULE}/successful`}
            element={<ChatbotJobSuccess />}
          />
          <Route path={`/track-package/:id`} element={<UserTrackPackage />} />
          <Route
            path="/plans-and-subscription/enterprise-contact-sales"
            element={<ContactSales />}
          />
          <Route path={`/${ONBOARDING}/*`} element={<Onboarding />} />
          <Route path={`/${FORGOTPASSWORD}`} element={<ForgotPassword />} />
          <Route path={`/${SCHEDULEDEMO}`} element={<ScheduleDemo />} />
          <Route path={`${SIGNUP}`} element={<SignUp />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="*" element={<Navigate to={"/"} />} />
          <Route path="/locker/signup/*" element={<LockerSignUp />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export const VendorApp = () => {
  return <Vendor />;
};

export default RouteDom;
