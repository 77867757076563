import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RidersType } from "../models/riders";

interface RidersListState {
  loading: boolean;
  pagination: {
    page: number;
    per_page: number;
  };
  riders: RidersType[];
}

const initialState: RidersListState = {
  loading: false,
  pagination: {
    page: 0,
    per_page: 10,
  },
  riders: [],
};

const ridersList = createSlice({
  name: "ridersList",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPagination(
      state,
      action: PayloadAction<{
        page: number;
        per_page: number;
      }>
    ) {
      state.pagination = action.payload;
    },
    setRidersList(state, action: PayloadAction<RidersType[]>) {
      state.riders = action.payload;
    },
    reset: () => initialState,
  },
});

export const ridersListActions = ridersList.actions;
export const ridersListReducer = ridersList.reducer;
