// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DeliveryTimeType,
  LateDeliveryFrequency,
  ImproveTimesEstimate,
} from "../models/onboarding/improveTimes.model";

interface ImproveTimeOnboardingType {
  city: string;
  cityOptions: string[];
  late_deliveries: boolean;
  deliveryInformation: ImproveTimeOnboardingDeliveryInformation[];
  suggestion: string | null;
  late_deliveries_frequency: LateDeliveryFrequency | null;
  estimate: ImproveTimesEstimate | null;
}

export interface ImproveTimeOnboardingDeliveryInformation {
  id: number;
  startCity: string;
  endCity: string;
  deliveryTimes: DeliveryTimeType[];
}

const initialState: ImproveTimeOnboardingType = {
  city: "",
  cityOptions: [],
  late_deliveries: false,
  deliveryInformation: [],
  suggestion: null,
  late_deliveries_frequency: null,
  estimate: null,
};

const improveTime = createSlice({
  name: "Improve Times",
  initialState,
  reducers: {
    setCity(state, action: PayloadAction<any>) {
      if (!state.cityOptions.includes(action.payload))
        state.cityOptions.push(action.payload);
      state.city = action.payload;
    },
    addDeliveryInformation(
      state,
      action: PayloadAction<ImproveTimeOnboardingDeliveryInformation>
    ) {
      state.deliveryInformation.push(action.payload);
    },
    removeDeliveryInformation(state, action: PayloadAction<number>) {
      const id = action.payload;

      state.deliveryInformation = state.deliveryInformation.filter(
        (item) => item.id !== id
      );
    },
    setDeliveryInformation(
      state,
      action: PayloadAction<ImproveTimeOnboardingDeliveryInformation[]>
    ) {
      state.deliveryInformation = action.payload;
    },
    setLateDelivery(state, action: PayloadAction<boolean>) {
      state.late_deliveries = action.payload;
    },
    setLateDeliveriesFrequency(
      state,
      action: PayloadAction<LateDeliveryFrequency | null>
    ) {
      state.late_deliveries_frequency = action.payload;
    },
    setCityOptions(state, action: PayloadAction<string[]>) {
      state.cityOptions = action.payload;
    },
    setSuggestion(state, action: PayloadAction<string>) {
      state.suggestion = action.payload;
    },
    setEstimate(state, action: PayloadAction<any>) {
      state.estimate = action.payload;
    },
    reset: () => initialState,
  },
});

export const improveTimeActions = improveTime.actions;
export const improveTimeReducer = improveTime.reducer;
