import { OverlayViewF, OverlayView } from "@react-google-maps/api";

interface Props {
  position: {
    lat: number;
    lng: number;
  };
  icon: string;
  title?: string;
  classname: string;
}

const CustomMarker = ({ position, title, icon, classname }: Props) => {
  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={() => ({
        x: -25,
        y: -25,
      })}
    >
      <img src={icon} alt={title} className={classname} />
      <h6
        style={{
          color: "#000000",
        }}
      >
        {title}
      </h6>
    </OverlayViewF>
  );
};

export default CustomMarker;
