import optimizeIcon from "../assets/images/optimize.png";
import micIcon from "../assets/images/mic.png";
import locateIcon from "../assets/images/locate.png";
import shareIcon from "../assets/images/share.png";
import cancelIcon from "../assets/images/cancel.png";
import greyBot from "../assets/icon/greybot.png";
import whiteBot from "../assets/icon/whitbot.png";
import dashboardIcon from "../assets/images/Dashboard icon.svg";
import settingsIcon from "../assets/images/Settings icon.svg";
import routesIcon from "../assets/images/routes.svg";
import reportsIcon from "../assets/images/Reports icon.svg";
import jobsIcon from "../assets/images/Jobs icons.svg";
import notifyIcon from "../assets/icon/notify.png";
import kolikoIcon from "../assets/icon/koliko.png";
import clockImg from "../assets/images/clock.svg";
import bikeImg from "../assets/images/bike.jpg";
import giftImg from "../assets/images/box.svg";
import editIcon from "../assets/icon/edit.svg";
import filterIcon from "../assets/icon/filter.svg";
import completedImg from "../assets/icon/completed.png";
import missedImg from "../assets/icon/missed.png";
import hourGlassImg from "../assets/icon/hourGlass.png";
import bikeIcon from "../assets/icon/bike.png";
import bikemanImg from "../assets/images/bikeman.jpg";
import uploadIcon from "../assets/icon/upload.png";
import backIcon from "../assets/icon/backarrow.svg";
import backShort from "./logos/backSmall.png";
import addJobIcon from "../assets/icon/addJobIcon.png";
import fileIcon from "../assets/icon/file.png";
import fileIconBlack from "./icon/fileIconBlack.svg";
import attach from "../assets/icon/attach.png";
import bulkTasks from "../assets/icon/bulk.png";
import copyIcon from "../assets/icon/copyIcon.png";
import expandDoc from "../assets/icon/Expand document.png";
import apiIcon from "../assets/icon/api.png";
import apiActive from "../assets/icon/apiActive.png";
import manualIcon from "./icon/manual.png";
import manualActive from "./icon/manualActive.png";
import clientJobs from "./icon/cliennt-jobs.svg";
import clientJobsActive from "./icon/client-jobsActive.svg";
import whatsappIcon from "../assets/icon/whatsapp.png";
import instaIcon from "../assets/icon/instagram.png";
import telegramIcon from "../assets/icon/telegram.png";
import whatsappActive from "../assets/icon/whatsappActive.png";
import instaActive from "../assets/icon/instagramActive.png";
import telegramActive from "../assets/icon/telegramActive.png";
import warning from "../assets/icon/warning.png";
import close from "../assets/icon/close.png";
import deliveryIcon from "./icon/delivery.svg";
import pickupIcon from "./icon/pickup.svg";
import forward from "./icon/Foward.svg";
import next from "./icon/next.png";
import lockerActive from "./icon/locker.svg";
import lockerActiveAlt from "./icon/lockerActiveAlt.svg";
import locker from "./icon/lockerActive.svg";
import location from "./icon/locationInfo.svg";
import locationActive from "./icon/locationActive.svg";
import locationActiveAlt from "./icon/locationActiveAlt.svg";
import marker from "./icon/marker.png";
import naijaFlag from "./icon/nigeria.png";
import box from "./icon/box.png";
import descBack from "./icon/descBack.png";
import catClose from "./icon/catClose.png";
import microphone from "./icon/microphone.png";
import mapLocation from "./icon/mapLocation.png";
import ovalPhone from "./icon/ovalPhone.png";
import carret from "./icon/caret.svg";
import deliveryDude from "./icon/delivery dude.png";
import dasboardIconActive from "./images/home active.svg";
import routesActive from "./images/routes active.svg";
import settingsActive from "./images/settings activr.svg";
import reportsActive from "./images/report active.svg";
import jobsIconActive from "./images/Jobs Active.svg";
import firstOnboardingSlide from "./images/Onboarding1.svg";
import secondOnboardingSlide from "./images/OnboardingImage2.svg";
import signUpImage from "./images/signup_image.svg";
import motionsLogoWhite from "./images/motions_logo_white.svg";
import locationOutline from "./icon/locationOutline.png";
import dropdownIcon from "./icon/dropdownIcon.svg";
import dropdownIconWhite from "./icon/chevron white.svg";
import search from "./icon/Search.svg";
import caretOpenAlt from "./icon/caretOpenAlt.png";
import caretOpenWhite from "./icon/caretOpen.svg";
import caretAlt from "./icon/caretAlt.png";
import inputClose from "./icon/inputClose.png";
import clock from "./icon/clock3.png";
import phone from "./icon/phone.svg";
import chat from "./icon/chat.svg";
import totalTime from "./icon/totalTime.png";
import optimizeJobIcon from "./icon/optimizeIcon.png";
import optimizing from "./icon/optimizing.svg";
import forwardFilled from "./icon/forwardFilled.png";
import checkFilled from "./icon/checkFilled.svg";
import checkFilledAlt from "./icon/checkFilledAlt.svg";
import xmark from "./icon/x-mark.png";
import check from "./icon/check.png";
import lockerSmall from "./icon/lockersmall.png";
import blueTick from "./icon/blueTick.png";
import motionsLogo from "./icon/logo.png";
import fullLogo from "./images/motions logo, black.png";
import emoji from "./icon/emoji.png";
import dispatcher from "./icon/disptacher.svg";
import buttonClose from "./icon/buttonClose.png";
import buttonOpen from "./icon/buttonOpen.png";
import addIconBlack from "./icon/addIconBlack.png";
import minusIconBlack from "./icon/minusIconBlack.svg";
import logoAlt from "./icon/LogoBlack.png";
import nextAlt from "./icon/nextAlt.png";
import logoWhite from "./images/LogoWhite.png";
import deliveryTime from "./icon/DeliveryTimes.svg";
import motionsLg from "./logos/motions-lg.svg";
import smiles from "./images/smiles.svg";
import satisfaction from "./icon/satisfaction.svg";
import maintenance from "./icon/maintenance.svg";
import perfomance from "./icon/perfomance.svg";
import preferences from "./icon/preferences.svg";
import calculatedImg from "./images/calculated.svg";
import NairaIcon from "./images/naira.svg";
import calculatorImg from "./images/calculator.svg";
import checkWhite from "./icon/checkWhite.svg";
import dashboardIconText from "./icon/dashboard text.svg";
import jobIconText from "./icon/addjobtext.svg";
import giftBox from "./icon/giftbox.svg";
import bikeIconWhite from "./icon/bikeIconWhite.svg";
import switchArrows from "./icon/left-and-right-arrows.svg";
import usermanagement from "./icon/user management.svg";
import wallet from "./icon/wallet.svg";
import pricing from "./icon/pricing & billing.svg";
import operatinghours from "./icon/operating hours.svg";
import ordermanagement from "./icon/order management.svg";
import upgrade from "./icon/upgrade.png";
import linkIcon from "./icon/linkIcon.svg";
import backarrowBlue from "./icon/backarrow blue.png";
import createUser from "./icon/createuser.svg";
import sadFace from "./icon/sad.svg";
import numbFace from "./icon/numb.svg";
import happyFace from "./icon/happy.svg";
import ngflag from "./icon/NGN.svg";
import usflag from "./icon/usd.svg";
import nosupport from "./icon/notsupported.svg";
import forwardWhite from "./icon/forwardWhite.svg";
import mapLocationAlt from "./icon/mapLocationAlt.svg";
import caretSlim from "./icon/prev.png";
import logoIcon from "./icon/logoIcon.svg";
import reduced from "./icon/reduced.png";
import infoIcon from "./icon/Information icon.svg";
import share from "./icon/fi_upload.png";
import externalLink from "./icon/external link.svg";
import sleeping from "./icon/sleeping copy.svg";
import deleteIcon from "./icon/delete.svg";
import arrowCircle from "./icon/arrow circle.svg";
import destinationMarker from "./icon/destination.svg";
import locationMarker from "./icon/location.svg";
import noUsers from "./images/no users.png";
import comingsoon from "./icon/comingsoon.png";
import tickets from "./icon/tickets icon.svg";
import notificationSettings from "./icon/notification settings.svg";
import noNotifications from "./icon/notifications.svg";
import noTickets from "./icon/noTickets.svg";
import urgent from "./icon/priority urgent.svg";
import high from "./icon/priority high.svg";
import low from "./icon/priority low.svg";
import sendMesage from "./icon/send message icon.svg";
import urgentSingle from "./icon/urgent single.svg";
import urgentSingleActive from "./icon/urgent single white.svg";
import highSingle from "./icon/high single.svg";
import highSingleActive from "./icon/high single white.svg";
import mediumSingle from "./icon/medium single.svg";
import mediumSingleActive from "./icon/medium single white.svg";
import lowSingle from "./icon/low single.svg";
import lowSingleActive from "./icon/low single white.svg";
import caretDownWhite from "./icon/caret down.svg";
import caretDownBlack from "./icon/caret down black.svg";
import attachment from "./icon/attachment.svg";
import mapTile from "./icon/Map Tile.svg";
import stop from "./icon/music-stop.svg";
import options from "./icon/options.svg";
import norider from "./icon/no rider.svg";
import notFound from "./icon/Not found.png";
import locked from "./icon/lock.svg";
import boxsmall from "./icon/box-flat.svg";
import boxLarge from "./icon/box-long.svg";
import boxOversized from "./icon/box-oversized.svg";
import male from "./icon/male.svg";
import maleActive from "./icon/male-active.svg";
import female from "./icon/female.svg";
import femaleActive from "./icon/female-active.svg";
import bag from "./images/paperbag.svg";
import contact from "./icon/contact.svg";
import copyWhite from "./icon/copy icon White.png";

export const logo =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665407002/motions_logo_white_1_tmptou.png";
export const bgImage =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665435536/Mask_group_3_efj3uq.jpg";
const caret =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665503024/Vector_ztxgej.png";

const caretOpen =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665510253/Vector_1_z4ovlp.png";
const manualInput =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665503024/Group_139_ssl0bc.png";

export const icons = {
  copyWhite,
  boxLarge,
  boxOversized,
  boxsmall,
  options,
  sleeping,
  mapLocationAlt,
  createUser,
  usermanagement,
  comingsoon,
  wallet,
  sadFace,
  norider,
  numbFace,
  preferences,
  happyFace,
  arrowCircle,
  locationMarker,
  locked,
  destinationMarker,
  sendMesage,
  nosupport,
  infoIcon,
  reduced,
  forwardWhite,
  caretSlim,
  caretDownWhite,
  caretDownBlack,
  pricing,
  reportsActive,
  settingsActive,
  operatinghours,
  ordermanagement,
  box,
  attachment,
  upgrade,
  deleteIcon,
  apiIcon,
  linkIcon,
  apiActive,
  dispatcher,
  addIconBlack,
  minusIconBlack,
  bikeIconWhite,
  backarrowBlue,
  backShort,
  switchArrows,
  externalLink,
  emoji,
  search,
  attach,
  buttonClose,
  nextAlt,
  buttonOpen,
  bulkTasks,
  motionsLogo,
  dashboardIconText,
  jobIconText,
  giftBox,
  xmark,
  check,
  totalTime,
  optimizeJobIcon,
  deliveryTime,
  lockerSmall,
  notFound,
  blueTick,
  optimizing,
  satisfaction,
  maintenance,
  perfomance,
  chat,
  close,
  inputClose,
  caretOpenAlt,
  caretOpenWhite,
  caretAlt,
  forwardFilled,
  phone,
  forward,
  catClose,
  checkWhite,
  deliveryDude,
  locationOutline,
  dropdownIcon,
  dropdownIconWhite,
  carret,
  descBack,
  clock,
  ovalPhone,
  NairaIcon,
  next,
  microphone,
  marker,
  naijaFlag,
  whatsappActive,
  location,
  locationActive,
  manualIcon,
  manualActive,
  clientJobs,
  clientJobsActive,
  locker,
  lockerActive,
  locationActiveAlt,
  lockerActiveAlt,
  whatsappIcon,
  instaActive,
  instaIcon,
  telegramActive,
  telegramIcon,
  copyIcon,
  expandDoc,
  greyBot,
  mapLocation,
  pickupIcon,
  deliveryIcon,
  whiteBot,
  backIcon,
  addJobIcon,
  caret,
  caretOpen,
  manualInput,
  dashboardIcon,
  routesIcon,
  reportsIcon,
  jobsIcon,
  settingsIcon,
  notifyIcon,
  kolikoIcon,
  editIcon,
  filterIcon,
  bikeIcon,
  uploadIcon,
  fileIcon,
  fileIconBlack,
  warning,
  dasboardIconActive,
  routesActive,
  jobsIconActive,
  checkFilled,
  checkFilledAlt,
  calculatorImg,
  share,
  noUsers,
  noNotifications,
  notificationSettings,
  noTickets,
  urgent,
  high,
  low,
  lowSingle,
  lowSingleActive,
  urgentSingle,
  urgentSingleActive,
  highSingle,
  highSingleActive,
  mediumSingle,
  mediumSingleActive,
  mapTile,
  stop,
  male,
  maleActive,
  female,
  femaleActive,
  contact,
};
export const dashboardAssets = {
  clockImg,
  bikeImg,
  giftImg,
  completedImg,
  missedImg,
  hourGlassImg,
  bikemanImg,
  tickets,
};
export const homeIcons = {
  optimizeIcon,
  micIcon,
  locateIcon,
  shareIcon,
  cancelIcon,
  bag,
};

export const onboardingIcons = {
  firstOnboardingSlide,
  secondOnboardingSlide,
  signUpImage,
  motionsLogoWhite,
  motionsLg,
  smiles,
  calculatedImg,
};

export const motionsLogos = {
  motionsLogo,
  fullLogo,
  logoAlt,
  logoWhite,
  logoIcon,
};

export const flags = {
  ngflag,
  usflag,
};
