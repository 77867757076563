import React from "react";
import backdrop from "../../assets/images/Screenshot 2023-01-18 at 5.41.02 PM.png";
import classes from "./bgVideo.module.scss";

const BackgroundVideo = () => {
  return (
    <div className={classes.backgroundVideo}>
      <img src={backdrop} alt="" />
      <span></span>
    </div>
  );
};

export default BackgroundVideo;
