import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TicketsSlice {
  pagination: { page: number; per_page: number };
  status: string | undefined;
  activeTab: number;
}

const initialState: TicketsSlice = {
  pagination: { page: 0, per_page: 10 },
  status: "open",
  activeTab: 0,
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload;
    },
    setPerpage(state, action: PayloadAction<number>) {
      state.pagination.per_page = action.payload;
    },
    setStatus(state, action: PayloadAction<string | undefined>) {
      state.status = action.payload;
    },
    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },
  },
});

export const ticketsSliceActions = ticketsSlice.actions;
export const ticketsSliceReducer = ticketsSlice.reducer;
