/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from "react";
import success from "../../../assets/images/wallet funded.gif";
import classes from "./firstjob.module.scss";
import { Link } from "react-router-dom";

interface Props {
  header: string;
  subtext: string;
  redirectUrl?: string;
  buttonText?: string;
}

const PaymentSuccessful: FC<Props> = ({
  header,
  subtext,
  redirectUrl,
  buttonText,
}) => {
  return (
    <div>
      <div className={classes.firstjob}>
        <img src={success} alt="" />
        <div>
          <h2 className="fw-bolder">{header}</h2>
          <p>{subtext}</p>
        </div>
        {redirectUrl && buttonText && (
          <Link to={redirectUrl}>{buttonText}</Link>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccessful;
