// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertDateToIsoString } from "../helpers";
import { Job } from "../models/jobs";

export type JobCategory =
  | "completed"
  | "cancelled"
  | "intransit"
  | "optimized"
  | "unassigned"
  | "assigned"
  | "late"
  | "missed"
  | "failed"
  | null;

type UseJobReducerState = {
  loading: boolean;
  error: boolean;
  pagination: {
    page: number;
    per_page: number;
  };
  filter: {
    status: JobCategory;
    date?: string;
  };
  jobs: Job[];
  hasNextPage: boolean;
};

const initialState: UseJobReducerState = {
  loading: false,
  error: false,
  pagination: {
    page: 0,
    per_page: 10,
  },
  filter: {
    status: "unassigned",
    date: convertDateToIsoString(),
  },
  jobs: [],
  hasNextPage: true,
};

const jobRetriver = createSlice({
  name: "jobRetriver",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPagination(
      state,
      action: PayloadAction<{
        page: number;
        per_page: number;
      }>,
    ) {
      state.pagination = action.payload;
    },
    setFilters(
      state,
      action: PayloadAction<{
        status: JobCategory;
        date?: string;
      }>,
    ) {
      state.filter = { ...state.filter, ...action.payload };
      state.pagination.page = 0;
    },
    setJobResponse(state, action: PayloadAction<Job[]>) {
      state.jobs = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reset: () => initialState,
  },
});

export const jobRetriverActions = jobRetriver.actions;
export const jobRetriverReducer = jobRetriver.reducer;
