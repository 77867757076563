import { useJsApiLoader } from "@react-google-maps/api";
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { libraries } from "./components/MapDisplay/MapDisplay";
import Loader from "./components/PageLoader/Loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { interceptors } from "./interceptors";
import { getApp } from "./routes/utils/helpers";
import * as Sentry from "@sentry/react";

interceptors.errorInterceptor();

function App() {
  const CurrentApp = getApp();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.REACT_APP_GOOGLEMAP_KEY,
    libraries,
  });

  //TODO: Find better way to show install prompt

  // const dispatch = useAppDisPatch();

  // useEffect(() => {
  //   const handleBeforeInstallPrompt = (event: Event) => {
  //     event.preventDefault();
  //     dispatch(downloadActions.setDownloadPrompt(event));
  //     dispatch(downloadActions.setVisible(true));
  //   };

  //   window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

  //   return () => {
  //     window.removeEventListener(
  //       "beforeinstallprompt",
  //       handleBeforeInstallPrompt,
  //     );
  //   };
  // }, []);

  if (!isLoaded) return <Loader />;

  return (
    <BrowserRouter>
      <ScrollToTop>
        <CurrentApp />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
