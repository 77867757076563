import { SuccessHttpResponse } from "@/models/https";
import { Job, NewJobType } from "@/models/jobs";
import { JobCategory } from "@/redux/jobsRetriverSlice";
import { apiSlice } from "./apiSlice";

const serviceEndpoint = `/jobs-service/vendor-jobs`;

export const jobUploadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createVendorJob: builder.mutation<Job, NewJobType>({
      query: (data) => ({
        url: serviceEndpoint,
        method: "POST",
        data,
      }),
      transformResponse: (res: SuccessHttpResponse<Job>) => res.data,
    }),
    getVendorsCreatedJobs: builder.query<
      Job[],
      { page: number; status: JobCategory; perPage: number }
    >({
      query: ({ page, status, perPage }) => ({
        url: `${serviceEndpoint}?page=${page}&per_page=${perPage}&status=${status}`,
        method: "GET",
      }),
      transformResponse: (res: SuccessHttpResponse<Job[]>) => res.data,
    }),
  }),
});

export const { useCreateVendorJobMutation, useGetVendorsCreatedJobsQuery } =
  jobUploadApiSlice;
