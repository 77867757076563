import {
  ScheduledJob,
  ScheduledJobResponse,
  Vendor,
  VendorAuthResponse,
  VendorCouriersResponse,
  VendorLocationResponse,
  VendorSignInData,
} from "@/models/vendor";
import { apiSlice } from "../apiSlice";
import { SuccessHttpResponse } from "@/models/https";

const authEndpoint = `/auth-service/vendor`;
const locationEndpoint = `/auth-service/vendor/locations`;
const jobScheduleEndpoinnt = `/jobs-service/vendor-job-schedule`;

export const vendorServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourierVendorDetails: builder.query<VendorSignInData, string>({
      query: (id: string) => ({
        url: `${authEndpoint}/accounts/no-auth-data/${id}`,
        method: "GET",
      }),
      transformResponse: (response: SuccessHttpResponse<VendorSignInData>) =>
        response.data,
    }),
    sendVendorLoginOTP: builder.mutation({
      query: ({ login, courier }) => ({
        url: `${authEndpoint}/login/${courier}`,
        method: "POST",
        data: login,
      }),
      transformResponse: (response: SuccessHttpResponse<null>) =>
        response.message,
    }),
    verifyVendorLoginOTP: builder.mutation({
      query: ({ data, courier }) => ({
        url: `${authEndpoint}/login/verify-otp/${courier}`,
        method: "POST",
        data,
      }),
      transformResponse: (response: SuccessHttpResponse<VendorAuthResponse>) =>
        response.data,
    }),
    getVendorAssociatedCourier: builder.mutation({
      query: ({ login }) => ({
        url: `${authEndpoint}/vendor-couriers`,
        method: "POST",
        data: login,
      }),
      transformResponse: (
        response: SuccessHttpResponse<VendorCouriersResponse[]>,
      ) => response.data,
    }),
    editVendorAccountDetails: builder.mutation({
      query: (data) => ({
        url: `${authEndpoint}/account-update`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        data,
        formData: true,
      }),
      transformResponse: (response: SuccessHttpResponse<Vendor>) =>
        response.data,
    }),
    getVendorLocations: builder.query<
      VendorLocationResponse[],
      { page: number; per_page: number }
    >({
      query: ({ page, per_page }) => ({
        url: `${locationEndpoint}?page=${page}&per_page=${per_page}`,
        method: "GET",
      }),
      providesTags: ["Locations"],
      transformResponse: (
        response: SuccessHttpResponse<VendorLocationResponse[]>,
      ) => response.data,
    }),
    addNewVendorLocation: builder.mutation({
      query: ({ data, id }) => ({
        url: `${locationEndpoint}/${id}`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["Locations"],
    }),
    updateSingleVendorLocation: builder.mutation({
      query: ({ data, id }) => ({
        url: `${locationEndpoint}/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["Locations"],
    }),
    deleteSingleVendorLocation: builder.mutation({
      query: (id: string) => ({
        url: `${locationEndpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Locations"],
    }),
    getScheduledVendorJobs: builder.query<
      ScheduledJob[],
      { date: string; page: number; perPage: number }
    >({
      query: ({ date, page, perPage }) => ({
        url: `${jobScheduleEndpoinnt}?date=${date}&page=${page}&per_page=${perPage}`,
        method: "GET",
      }),
      providesTags: ["VendorPendingJobs"],
      transformResponse: (res: SuccessHttpResponse<ScheduledJob[]>) => res.data,
    }),
    scheduleNewVendorJob: builder.mutation<{ customer_link: string }, any>({
      query: (data) => ({
        url: jobScheduleEndpoinnt,
        method: "POST",
        data,
        FormData: true,
      }),
      invalidatesTags: ["VendorPendingJobs"],
      transformResponse: (
        res: SuccessHttpResponse<{ customer_link: string }>,
      ) => res.data,
    }),
    updateScheduledVendorJob: builder.mutation({
      query: ({ data, id }) => ({
        url: `${jobScheduleEndpoinnt}/${id}`,
        method: "PATCH",
        data,
        FormData: true,
      }),
      invalidatesTags: ["VendorPendingJobs"],
    }),
    getScheduledVendorJob: builder.query<ScheduledJob, string>({
      query: (id) => ({
        url: `${jobScheduleEndpoinnt}/${id}`,
        method: "GET",
      }),
      transformResponse: (res: SuccessHttpResponse<ScheduledJobResponse>) =>
        res.data.customer_link,
    }),
    deleteScheduledVendorJob: builder.mutation({
      query: (id) => ({
        url: `${jobScheduleEndpoinnt}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VendorPendingJobs"],
    }),
    getAllCourierVendors: builder.query<
      Omit<Vendor, "courier_img_url">[],
      undefined
    >({
      query: () => ({
        url: `${authEndpoint}/accounts`,
        method: "GET",
      }),
      transformResponse: (
        res: SuccessHttpResponse<Omit<Vendor, "courier_img_url">[]>,
      ) => res.data,
      providesTags: ["Vendors"],
    }),
    registerNewVendor: builder.mutation({
      query: (data) => ({
        url: `${authEndpoint}/register`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["Vendors"],
    }),
    resendVendorRegistrationLink: builder.mutation<string, string>({
      query: (id) => ({
        url: `${authEndpoint}/resend-signin-link/${id}`,
        method: "POST",
      }),
      transformResponse: (res: SuccessHttpResponse<null>) => res.message,
    }),
    deleteVendorAccount: builder.mutation({
      query: (id) => ({
        url: `${authEndpoint}/accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Vendors"],
    }),
    downloadVendorCSVsampleFile: builder.query({
      query: () => ({
        url: `${authEndpoint}/download/`,
        method: "GET",
      }),
    }),
    uploadAddVendorCsvFile: builder.mutation({
      query: (data) => ({
        url: `${authEndpoint}/upload/`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        data,
        formData: true,
      }),
      invalidatesTags: ["Vendors"],
    }),
    retrieveLoggedInVendorDetails: builder.query<VendorAuthResponse, string>({
      query: (id) => ({
        url: `${authEndpoint}/accounts/${id}`,
        method: "GET",
      }),
      transformResponse: (res: SuccessHttpResponse<VendorAuthResponse>) =>
        res.data,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCourierVendorDetailsQuery,
  useSendVendorLoginOTPMutation,
  useVerifyVendorLoginOTPMutation,
  useGetVendorAssociatedCourierMutation,
  useEditVendorAccountDetailsMutation,
  useGetVendorLocationsQuery,
  useAddNewVendorLocationMutation,
  useUpdateSingleVendorLocationMutation,
  useDeleteSingleVendorLocationMutation,
  useGetScheduledVendorJobsQuery,
  useGetScheduledVendorJobQuery,
  useScheduleNewVendorJobMutation,
  useDeleteScheduledVendorJobMutation,
  useUpdateScheduledVendorJobMutation,
  useGetAllCourierVendorsQuery,
  useRegisterNewVendorMutation,
  useResendVendorRegistrationLinkMutation,
  useDeleteVendorAccountMutation,
  useLazyDownloadVendorCSVsampleFileQuery,
  useUploadAddVendorCsvFileMutation,
  useRetrieveLoggedInVendorDetailsQuery,
} = vendorServiceApi;
