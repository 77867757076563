// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthResponse, RbUserPermissions } from "../models/auth";
import { vendorServiceApi } from "@/services/Vendor/vendor";
import { userManagementApiSlice } from "@/services/userManagement";

const initialState: {
  access_token?: string;
  token: AuthResponse;
  permissions: RbUserPermissions;
} = {
  access_token: "",
  token: {
    email: "",
  },
  permissions: {
    role: null,
    permissions: { id: "" },
  },
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication(state, action: PayloadAction<AuthResponse>) {
      state.token = { ...action.payload };
      state.access_token = action.payload.access_token;
    },
    setRbUserPermissions(state, action: PayloadAction<any>) {
      state.permissions = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.access_token = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      vendorServiceApi.endpoints.verifyVendorLoginOTP.matchFulfilled,
      (state, { payload }) => {
        state.access_token = payload.access_token;
      },
    );
    builder.addMatcher(
      userManagementApiSlice.endpoints.getLoggedInRbUserRoles.matchFulfilled,
      (state, { payload }) => {
        state.permissions = payload;
      },
    );
  },
});

export const authActions = auth.actions;
export const authReducer = auth.reducer;
