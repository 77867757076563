import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "../components/PageLoader/Loader";
import StartScreen from "../components/StartScreen/StartScreen";
import useRetrieveSession from "../hooks/useRetrieveSession";
import { DASHBOARD, SIGNIN } from "./routeConstants";
import Intro from "../views/onboarding/Intro";

const DashboardPage = lazy(() => import("../views/dashboard/DashboardPage"));
const SignIn = lazy(() => import("../views/signIn/SignIn"));

const MainAppRoutes = () => {
  const { fetchingAuthorization } = useRetrieveSession();

  if (fetchingAuthorization) return <StartScreen />;

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path={`/${DASHBOARD}/*`} element={<DashboardPage />} />
        <Route path={`${SIGNIN}`} element={<SignIn />} />

        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </Suspense>
  );
};

export default MainAppRoutes;
