// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../models/jobs";
import { ChatbotData } from "../models/chatbot/chatbotJobs";
import { jobUploadApiSlice } from "@/services/jobUpoad";
// import

const initialState: { job: Job | null; botData: ChatbotData | null } = {
  job: null,
  botData: null,
};

const newJob = createSlice({
  name: "New Job",
  initialState,
  reducers: {
    setJob(state, action: PayloadAction<Job>) {
      state.job = action.payload;
    },
    setBotData(state, action: PayloadAction<ChatbotData>) {
      state.botData = action.payload;
    },
    setData(state, action: PayloadAction<any>) {
      state = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      jobUploadApiSlice.endpoints.createVendorJob.matchFulfilled,
      (state, { payload }) => {
        state.job = payload;
      },
    );
  },
});

export const newJobActions = newJob.actions;
export const newJobReducer = newJob.reducer;
