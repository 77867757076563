export const SIGNUP = "sign-up";
export const SIGNIN = "login";
export const SETUP = "dashboard-setup";
export const ADDJOB = "jobs/add-job";
export const DASHBOARD = "dashboard";
export const ROUTES = "routes";
export const REPORTS = "reports";
export const JOBS = "jobs";
export const HOME = "home";
export const RIDERS = "riders";
export const ADDRIDER = "add-rider";
export const EDITRIDER = "edit-rider";
export const MAPS = "maps";
export const FORM = "fillform";
export const DELIVERIES = "deliveries";
export const MYDELIVERIES = "my-deliveries";
export const TRACKPACKAGE = "dashboard/jobs/track-order";
export const MANUALINPUT = "manual-job";
export const APIPLUGIN = "api-plugin";
export const CLIENTMANAGMENT = "client-management";
export const BOTS = "bots";
export const IMPORT = "import-tasks";
export const VERIFYEMAIL = "verify-email";
export const SCHEDULEDEMO = "schedule-demo";
export const WELCOME = "welcome";
export const PRICING = "plans-and-subscription";
export const SCHEDULE = "schedule-delivery";

export const ONBOARDING = "onboarding";
export const DELIVERYTIME = "onboarding/delivery-times";
export const PRODUCTIVITY = "onboarding/productivity";
export const SATISFACTION = "onboarding/satisfaction";
export const FINANCIAL = "onboarding/financial-perfomance";
export const FLEETMANAGEMENT = "onboarding/management";

export const SETTINGS = "settings";
export const SERVICEAREA = "pricing-model/service-area";
// export const SETTINGS = "settings";

export const VERIFICATIONPROCESS = "auth/create/verify";
export const NONADMINSIGNUP = "auth/complete-non-admin-reg/:token";
export const FORGOTPASSWORD = "auth/forgot-password";
export const RESETPASSWORD = "auth/password-reset/:uid/:authtoken";
