import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { interceptors } from "./interceptors";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ReactGA from "react-ga4";
import { configSetting } from "./config";
import Tagmanager from "react-gtm-module";
import { PrimeReactProvider } from "primereact/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

Sentry.init({
  dsn: "https://a9874209e6d48e00c208c6a21e4feb8a@o4508460116082688.ingest.us.sentry.io/4508460149637120",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "https://dash.motions.space",
    "https://vendor.motions.space",
    /^https:\/\/yourserver\.io\/api/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

interceptors.authInterceptor();

ReactGA.initialize(configSetting.gaMeasurementKey);

Tagmanager.initialize({
  gtmId: "GTM-TML98RZW",
});

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PrimeReactProvider>
          <App />
        </PrimeReactProvider>
        <ToastContainer
          theme="dark"
          position="top-right"
          autoClose={3000}
          closeOnClick
        />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);
