import React, { FC } from "react";
import classes from "./emptyState.module.scss";
import Button from "../Button/Button";

interface EmptyStateProps {
  icon?: string;
  mainText?: string;
  subText?: string;
  action?: () => void;
  actionText?: string;
  imageSize?: string;
  loading?: boolean;
}

const EmptyState: FC<EmptyStateProps> = ({
  icon,
  mainText,
  subText,
  action,
  actionText,
  imageSize = "100px",
  loading,
}) => {
  return (
    <div className={classes.emptyState_container}>
      <div className={classes.emptyState_content}>
        {icon && <img style={{ width: imageSize }} src={icon} alt="" />}
        <div>
          {mainText && <h6>{mainText}</h6>}
          {subText && <small>{subText}</small>}
          {action && actionText && (
            <Button text={actionText} onClick={action} loading={loading} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
