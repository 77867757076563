// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { guideSteps, ProductGuideType } from "../models/productGuide";

const initialState: { guide: ProductGuideType; loading: boolean } = {
  guide: {
    show_guide: true,
    started_guide: false,
    guide_steps: guideSteps,
    completed_guide: false,
  },
  loading: false,
};

const productGuide = createSlice({
  name: "Product guide",
  initialState,
  reducers: {
    setGuide(state, action: PayloadAction<ProductGuideType>) {
      state.guide = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    restartGuide: () => initialState,
  },
});

export const productGuideActions = productGuide.actions;
export const productGuideReducer = productGuide.reducer;
