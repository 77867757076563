import { icons } from "./assets";
import Resizer from "react-image-file-resizer";
import { LatLngType } from "./components/MapDisplay/MapDisplay";
import { LockersResult } from "./models/address";
import { toast } from "react-toastify";
import { GroupedNotifications, NotificationItem } from "./models/notification";

export const check = "true";

export const debounce = (func: (...args: any[]) => void) => {
  let timer: null | NodeJS.Timeout = null;
  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func(...args);
    }, 100);
  };
};

export const clsx = (...args: string[]) => `${args.join(" ")}`;

export const convertDate = (date: any = "") => {
  let dateToConvert;
  if (date === "") {
    dateToConvert = new Date();
  } else {
    dateToConvert = new Date(date);
  }

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const daysFull = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsFull = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Septempber",
    "October",
    "November",
    "December",
  ];

  const curr = dateToConvert.getDate();
  const day = days[dateToConvert.getDay()];
  const month = months[dateToConvert.getMonth()];
  const year = dateToConvert.getFullYear();
  const dayFull = daysFull[dateToConvert.getDay()];
  const monthFull = monthsFull[dateToConvert.getMonth()];

  return {
    day,
    dayFull,
    month,
    date: curr,
    fullDate: `${day}, ${month} ${curr}, ${year}`,
    dayAndMonth: `${day}, ${curr} ${month}`,
    MonthDateYear: `${month} ${curr}, ${year}`,
    dayAndMonthFull: `${dayFull}, ${curr} ${monthFull}`,
  };
};

export const convertTime = (time: any = "") => {
  let timeToConvert;

  if (time === "") {
    timeToConvert = new Date();
  } else {
    timeToConvert = new Date(time);
  }

  const hrs = timeToConvert.getHours();
  const mins = timeToConvert.getMinutes();
  const AmOrPm = hrs < 12 ? "AM" : "PM";

  const hours = hrs === 0 ? "12" : hrs % 12 < 10 ? `0${hrs % 12}` : hrs % 12;
  const minutes = mins < 10 ? `0${mins}` : mins;

  return `${hours}:${minutes} ${AmOrPm}`;
};

export const splitContactNumber = (contactNumber: string) => {
  const firstNumber = contactNumber.substring(0, 3);
  const secondNumber = contactNumber.substring(3, 7);
  const thirdNumber = contactNumber.substring(7, contactNumber.length);
  return { firstNumber, secondNumber, thirdNumber };
};

export function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "...." : str;
}

export const convertDateToIsoString = () => {
  const date = new Date();
  const formattedDate = date.toISOString().substring(0, 10);
  return formattedDate;
};

export const getScheduleTimeRange = (start: number, end: number) => {
  let timeRangeToString = "";

  if (start < 12 && end < 12) {
    timeRangeToString = `${start}:00-${end}:00 AM`;
  }
  if (start >= 12 && end >= 12) {
    timeRangeToString = `${start > 12 ? start - 12 : 12}:00-${
      end > 12 ? end - 12 : 12
    }:00 PM`;
  }
  if (start < 12 && end >= 12) {
    timeRangeToString = `${start}:00AM-${end > 12 ? end - 12 : 12}:00 PM`;
  }

  return timeRangeToString;
};

export const numberWithCommas = (x: number, hasDecimal = true) => {
  if (!hasDecimal) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getAmmountInDifferentFormats = (
  amount: number,
  code = "NGN",
  symbol = "₦",
) => {
  return {
    raw: amount,
    formatted: numberWithCommas(amount),
    formatted_with_code: `${numberWithCommas(amount)} ${code}`,
    formatted_with_symbol: `${symbol} ${numberWithCommas(amount)}`,
  };
};

export const calculateSum = (array: any, property: string) => {
  const total = array.reduce((accumulator: any, object: any) => {
    return accumulator + object[property];
  }, 0);

  return total;
};

export const phoneNumberToString = (
  phone: string | number,
  countryCode = "234",
) => {
  const numberToString: string = phone.toString();

  const numbersArray = numberToString.split("");

  if (numbersArray[0] === "+") {
    return numberToString;
  }

  if (numbersArray[0] === "0") {
    return `+${countryCode}${numberToString.slice(1)}`;
  }

  return `+${countryCode}${numberToString}`;
};

export const generateJobID = (length = 8) => {
  let result = "MT-";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const copyTextToClipboard = (
  text: string,
  successCallback: () => void,
) => {
  navigator.clipboard.writeText(text);
  successCallback();
};

export const getPlanDetails = (name: string) => {
  const splitName = name.split("_");

  const planname = splitName[0];

  if (splitName.length === 1 || splitName[splitName.length - 1] === "monthly") {
    return {
      name: planname,
      paymentPlan: "per month",
    };
  }

  return {
    name: planname,
    paymentPlan: "per 3 months",
  };
};

export const getBotChannel = (channel: string) => {
  const botChannels: { [key: string]: { icon: string; url: string } } = {
    whatsapp: {
      icon: icons.whatsappActive,
      url: `https://wa.me/2348087214620`,
    },
    instagram: {
      icon: icons.instaActive,
      url: `https://www.instagram.com/motions_delivery/`,
    },
  };

  return botChannels[channel];
};

export const extractKeys = (obj: Record<string, any>): string[] => {
  let keys: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      keys.push(key);
      if (typeof obj[key] === "object" && obj[key] !== null) {
        keys = keys.concat(extractKeys(obj[key]));
      }
    }
  }

  return keys;
};

export const groupObjectsByTwoProperties = (
  arr: any,
  property1: any,
  property2: any,
) => {
  const groupedObjects: any = {};

  arr.forEach((obj: any) => {
    const key = `${obj[property1]}_${obj[property2]}`;
    if (!groupedObjects[key]) {
      groupedObjects[key] = [];
    }
    groupedObjects[key].push(obj);
  });

  return Object.keys(groupedObjects).map((key) => ({
    title: key,
    data: groupedObjects[key],
  }));
};

export const convertNumberToTime = (hour: number) => {
  if (hour >= 0 && hour <= 23) {
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const period = hour < 12 ? "AM" : "PM";
    return `${formattedHour}:${hour % 12 === 0 ? "00" : "00"} ${period}`;
  } else {
    return "Invalid hour, please provide a number between 0 and 23.";
  }
};

export const imageFileResizer = (
  file: File,
  callback: (file: any) => void,
  exportFormat: "base64" | "file" | "blob",
) => {
  try {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => callback(uri),
      exportFormat,
    );
  } catch (error) {
    console.log(error);
  }
};

export const replaceInString = function (
  fullString: string,
  search: string,
  replacement: string,
) {
  return fullString.split(search).join(replacement);
};

export const calculateDistance = (
  origin: LatLngType,
  destination: LatLngType,
) => {
  if (!window.google || !window.google.maps || !window.google.maps.geometry) {
    return {
      meters: `0m`,
      kilometers: `0km`,
    };
  }

  const { computeDistanceBetween } = window.google.maps.geometry.spherical;
  const point1 = new google.maps.LatLng(origin.lat, origin.lng);
  const point2 = new google.maps.LatLng(destination.lat, destination.lng);

  const distanceInMeters = computeDistanceBetween(point1, point2);
  const distanceInKilometers = Math.floor(distanceInMeters / 1000);

  return {
    meters: `${distanceInMeters} m`,
    kilometers: `${distanceInKilometers} km`,
  };
};

export const groupBy = (array: any[], key: string) => {
  return array.reduce((result, item) => {
    // Get the value of the key for the current item
    const keyValue = item[key];

    // If the key value is not yet a property in the result object, create an array for it
    if (!result[keyValue]) {
      result[keyValue] = [];
    }

    // Push the current item into the array for the key value
    result[keyValue].push(item);

    return result;
  }, {});
};

export const selectAvailableLockerCell = (
  size: string,
  locker: LockersResult,
) => {
  const availableLockerCells = locker.lockerCells.filter(
    (cell) => cell.status === "active",
  );

  const availableLockerCellsOfSelectedSize = availableLockerCells.filter(
    (cell) => cell.cell_category === size,
  );

  const otherAvalableLockerCells = availableLockerCells.filter(
    (cell) => cell.cell_category !== size,
  );

  const selectedCellIndex = availableLockerCellsOfSelectedSize.length
    ? Math.floor(Math.random() * availableLockerCellsOfSelectedSize.length)
    : 0;

  const otherCells = groupBy(otherAvalableLockerCells, "cell_category");

  return {
    selected_locker: availableLockerCellsOfSelectedSize.length
      ? {
          address: locker.address,
          select_cell_id:
            availableLockerCellsOfSelectedSize[selectedCellIndex].id,
          locker_id: locker.id,
        }
      : null,
    availableCells: Object.keys(otherCells).map(
      (key) => `${key} - ${otherCells[key].length}`,
    ),
  };
};

export const isEmailOrPhone = (input: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const phoneRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;

  if (emailRegex.test(input)) {
    return {
      email: input,
    };
  } else if (phoneRegex.test(input)) {
    return {
      phone: phoneNumberToString(input),
    };
  }
};

export const convertCoordToAddress = async (data: {
  lat: number;
  lng: number;
}) => {
  const { lat, lng } = data;
  const geocoder = new google.maps.Geocoder();

  const { results } = await geocoder.geocode({
    location: { lat, lng },
  });

  return results;
};

export const shareWithDeviceNativeShare = async (data: {
  title: string;
  text: string;
  url: string;
}) => {
  const shareData = {
    title: data.title,
    text: data.text,
    url: data.url,
  };

  try {
    await navigator.share(shareData);
    toast.success("Shared successfully");
  } catch (err) {
    copyTextToClipboard(`${shareData.text} ${shareData.url}`, () =>
      toast.success("Copied to Clipboard"),
    );
  }
};

export function generateSlug(name: string): string {
  return name
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-");
}

function getDaysDifference(date1: Date, date2: Date): number {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.floor((date1.getTime() - date2.getTime()) / oneDay);
}

function parseDate(dateString: string): Date {
  return new Date(dateString);
}

export function groupNotificationsByDate(
  notifications: NotificationItem[],
): GroupedNotifications {
  const today: NotificationItem[] = [];
  const last7Days: NotificationItem[] = [];
  const last30Days: NotificationItem[] = [];

  const now = new Date();

  notifications.forEach((notification) => {
    const createdAt = parseDate(notification.created_at);
    const daysDifference = getDaysDifference(now, createdAt);

    if (daysDifference === 0) {
      today.push(notification);
    } else if (daysDifference <= 7) {
      last7Days.push(notification);
    } else if (daysDifference <= 30) {
      last30Days.push(notification);
    }
  });

  return { today, last7Days, last30Days };
}
