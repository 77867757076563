import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type NotificationCategory =
  | "operation updates"
  | "billing and wallet updates"
  | "communication updates";

interface NotificationSlice {
  pagination: { page: number; per_page: number };
  activeTab: number;
}

const initialState: NotificationSlice = {
  pagination: { page: 0, per_page: 10 },
  activeTab: 0,
};

const notificationSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload;
    },
    setPerpage(state, action: PayloadAction<number>) {
      state.pagination.per_page = action.payload;
    },
    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },
  },
});

export const notificationSliceActions = notificationSlice.actions;
export const notificationSliceReducer = notificationSlice.reducer;
