import { useEffect, useState } from "react";
import { useAppSelector, useAppDisPatch } from "../redux/store";
import { authActions } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

const useRetrieveSession = () => {
  const [fetchingAuthorization, setFetchingAuthorization] = useState(true);

  let timeout: any = null;

  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token.access_token) {
      const act = localStorage.getItem("motionspace_courier_token");
      if (act) {
        dispatch(authActions.setAuthentication(JSON.parse(act)));
        if (!window.location.href.includes("dashboard"))
          navigate("/dashboard/home");
      }
    }

    timeout = setTimeout(() => {
      setFetchingAuthorization(false);
    }, 3000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchingAuthorization: fetchingAuthorization,
  };
};

export default useRetrieveSession;
