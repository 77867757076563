import { IFleetManagement } from "../../redux/fleetManagementSlice";

export interface FleetManagementOnboardingFlowData
  extends FleetManagementOnboardingQuestions {
  description_for_other_vehicle_usage_and_maintenance: string;
  fleet_size: string;
  other_tracking_devices_brand?: string;
  tracker_company_email?: string;
  tracker_company_name?: string;
  tracker_company_phone?: string;
  tracking_devices_brand: string;
  vehicle_usage_and_maintenance: string;
  yearly_avg_maintenance_cost_per_vehicle: number;
}

export interface FleetManagementOnboardingQuestions {
  fleet_management_driver_behavior_analysis: boolean;
  fleet_management_fuel_monitoring: boolean;
  fleet_management_maintenance_scheduling: boolean;
  fleet_management_real_time_GPS_tracking: boolean;
  fleet_management_vehicle_diagnostics: boolean;
}

export interface FleetManagementEstimate {
  total_efficiency_improvement_percentage: string;
  potential_cost_savings: number;
  avg_maintenance_cost_per_vehicle: number;
  avg_maintenance_cost_per_vehicle_after: number;
  yearly_cost: number;
  yearly_cost_after: number;
  tracking_devices_brand: string;
  tracking_devices_brand_after: string;
}

export const initialState: IFleetManagement = {
  vehicleAmount: {
    id: 0,
    text: "",
  },
  maintenanceCost: "",
  vehicleTracking: {
    id: 0,
    text: "",
  },
  processDescription: "",
  deviceBrand: {
    id: 0,
    text: "",
  },
  managementMethod: [
    {
      isSelected: false,
      title: "Real-time GPS tracking",
      value: "fleet_management_real_time_GPS_tracking",
    },
    {
      isSelected: false,
      title: "Vehicle diagnostics",
      value: "fleet_management_vehicle_diagnostics",
    },
    {
      isSelected: false,
      title: "Maintenance scheduling",
      value: "fleet_management_maintenance_scheduling",
    },
    {
      isSelected: false,
      title: "Fuel monitoring",
      value: "fleet_management_fuel_monitoring",
    },
    {
      isSelected: false,
      title: "Driver behavior analysis.",
      value: "fleet_management_driver_behavior_analysis",
    },
  ],
  trackRiderMaintnance: {
    maintenance: {
      id: 0,
      text: "",
      value: "",
    },
    maintenanceOptions: [
      {
        id: 1,
        text: "Driver Text or Calls",
        value: "driver_text_or_calls",
      },
      {
        id: 2,
        text: "Manual Logs",
        value: "manual_logs",
      },
      {
        id: 3,
        text: "Spreadsheet",
        value: "spreadsheet",
      },
      {
        id: 4,
        text: "Mobile App Reporting",
        value: "mobile_app_reporting",
      },
    ],
    challenges: [
      {
        isSelected: false,
        title: "Ensuring Data Accuracy",
        value: "data_accuracy",
      },
      {
        isSelected: false,
        title: "Time Spent on Reports",
        value: "time_spent_on_report",
      },
      {
        isSelected: false,
        title: "Updating in Real-Time",
        value: "realtime_updating",
      },
      {
        isSelected: false,
        title: "Maintenance Scheduling",
        value: "maintenance_scheduling",
      },
    ],
  },
  partnershipMaintnance: {
    manageSchedule: {
      id: 0,
      text: "",
      value: "",
    },
    manageScheduleOptions: [
      {
        id: 1,
        text: "Ad-hoc basis",
        value: "ad-hoc_basis",
      },
      {
        id: 2,
        text: "Cordinated manually",
        value: "cordinated_manually",
      },
      {
        id: 3,
        text: "Scheduled by workshop",
        value: "workshop_scheduled",
      },
      {
        id: 4,
        text: "Using software tools",
        value: "software_tools",
      },
    ],
    improvements: [
      {
        isSelected: false,
        title: "Automated maintenance scheduling",
        value: "automated_maintenance_scheduling",
      },
      {
        isSelected: false,
        title: "Advanced GPS tracking integrations",
        value: "advanced_gps",
      },
      {
        isSelected: false,
        title: "Improved vehicle diagnostics",
        value: "improved_diagnostics",
      },
      {
        isSelected: false,
        title: "Enhanceed fuel monitoring",
        value: "fuel_monitoring",
      },
      {
        isSelected: false,
        title: "Driver behavior analysis and training",
        value: "driver_analysis",
      },
    ],
  },
  estimate: null,
};
