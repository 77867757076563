import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./service";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/v1",
  }),
  tagTypes: [
    "orderManagement",
    "VendorPendingJobs",
    "Locations",
    "VendorJobs",
    "Vendors",
    "Notifications-settings",
    "Notifications",
    "Complaint-Tickets",
    "Issue-Tickets",
    "Ticket-Data",
    "Issue-Ticket-Data",
    "Role-Based-Users",
  ],
  endpoints: () => ({}),
});
