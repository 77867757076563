import { motion, useAnimation } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import wave from "../../assets/icon/wave.png";
import classes from "./onboarding.module.scss";
import Button from "../../components/Button/Button";

const Welcome: FC = () => {
  const navigate = useNavigate();
  const [showSkip, setShowSkip] = useState(false);

  let timeout: any = null;

  const navigateToNext = () => {
    timeout = setTimeout(() => {
      navigate("/onboarding");
    }, 14000);
  };

  setTimeout(() => {
    setShowSkip(true);
  }, 3000);

  useEffect(() => {
    navigateToNext();
    return () => {
      if (timeout) clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const animationControls = useAnimation();
  const logoTextControl = useAnimation();
  const pathControl = useAnimation();
  const svgControl = useAnimation();

  useEffect(() => {
    async function LogoAnimate() {
      await svgControl.start({ opacity: 1, x: 0 });
      await pathControl.start({ pathLength: 1 });
      await pathControl.start({
        fill: "black",
        strokeWidth: "1",
        transition: { duration: 1 },
      });
      await svgControl.start({ scale: 0.6 });
      await svgControl.start({ scale: 1 });
      await svgControl.start({
        width: "40px",
        height: "40px",
        top: 30,
        left: 20,
      });
      await logoTextControl.start({ opacity: 1, left: 60, x: 0 });
    }
    LogoAnimate();
  }, [animationControls, logoTextControl, svgControl, pathControl]);
  return (
    <Container>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ position: "relative" }}
      >
        {showSkip && (
          <motion.div
            className={classes.skipButton}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Button text="SKIP" onClick={() => navigate("/onboarding")} />
          </motion.div>
        )}

        <div
          className={`${classes.pageContainer} d-flex flex-column align-items-start`}
        >
          <motion.div
            initial={{ opacity: 0, y: "100px" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1,
            }}
            exit={{ opacity: 0 }}
            style={{ position: "absolute", top: "15vh" }}
          >
            <h5 className={`${classes.purple} fw-bold`}>
              Hi there <img src={wave} alt="" />
            </h5>
            <h3 className="fw-bolder">Welcome to Motions!</h3>
          </motion.div>
          <motion.div
            className={classes.motionsLG}
            style={{ bottom: 0, left: 0, position: "absolute" }}
            initial={{
              opacity: 0,
              x: "-100vw",
              bottom: 0,
              left: 0,
            }}
            transition={{ duration: 1, ease: "easeInOut" }}
            animate={svgControl}
          >
            <motion.svg
              style={{ width: "90%", height: "90%" }}
              viewBox="0 0 428 568"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                initial={{ pathLength: 0 }}
                transition={{ duration: 8, ease: "easeInOut" }}
                fillRule="evenodd"
                clipRule="evenodd"
                strokeWidth="12"
                stroke="black"
                strokeLinecap="round"
                fill="transparent"
                strokeDasharray="0 1"
                d="M195.843 604C163.334 604 118.12 597.964 100.647 566.964C78.6691 527.956 110.968 480.273 158.619 421.701C146.438 399.467 130.72 367.618 109.03 322.999L108.626 322.167C81.9633 267.312 40.0244 181.026 18.157 153.955C17.2401 174.096 20.2788 225.419 44.8766 354.181C46.8413 364.494 41.2354 374.716 32.3288 376.991C23.4223 379.266 14.5943 372.774 12.6296 362.461C-29.5979 141.458 -15.7403 117.161 5.13766 109.518C33.2195 99.2349 60.0701 144.218 137.793 304.253C146.542 322.271 156.497 342.745 166.084 361.855C165.993 361.551 165.908 361.256 165.822 360.96C165.737 360.664 165.652 360.368 165.56 360.065C131.27 242.799 111.44 164.086 147.014 139.608C179.732 117.101 250.225 163.085 300.259 207.795C305.681 186.471 306.31 165.815 300.207 146.432C276.63 71.8142 152.253 12.2106 40.7377 49.0344C-58.1513 81.7026 -147.479 197.361 -122.881 317.478C-100.667 425.857 3.93265 472.842 15.7731 477.847C24.3391 481.456 28.7662 492.437 25.6489 502.355C22.5316 512.274 13.0488 517.4 4.48276 513.791C-9.08664 508.058 -128.801 454.187 -155.023 326.305C-183.996 184.894 -81.8584 49.7624 31.674 12.2409C151.1 -27.1915 299.473 32.7458 331.222 133.268C341.674 166.391 338.688 200.273 327.424 233.912C409.914 319.359 444.65 410.357 420.445 478.787C392.704 557.227 298.059 594.385 225.47 602.362C219.026 603.06 208.39 603.97 195.843 603.97V604ZM178.292 453.338C147.223 492.073 119.77 530.868 128.31 546.034C135.671 559.077 171.114 569.936 222.353 564.295C288.576 557.015 369.259 522.618 389.901 464.288C407.819 413.632 378.925 341.532 312.414 269.856C288.026 319.055 249.649 366.86 214.127 409.78C222.065 443.116 221.069 458.585 206.059 465.168C196.812 469.232 189.137 467.352 178.292 453.338ZM163.91 172.488C165.063 171.73 166.765 171.366 168.94 171.366C186.36 171.366 234.665 195.268 286.978 244.285C267.567 286.023 234.874 328.823 202.785 368.103C201.531 363.748 200.203 359.208 198.819 354.474C198.179 352.284 197.527 350.054 196.864 347.78L196.751 347.392C183.855 303.236 150.015 187.368 163.91 172.488Z"
                animate={pathControl}
              />
            </motion.svg>
          </motion.div>

          <motion.p
            style={{ position: "absolute", color: "black", top: 35 }}
            initial={{ opacity: 0, x: "100px" }}
            transition={{
              duration: 1,
            }}
            animate={logoTextControl}
          >
            motions
          </motion.p>
        </div>
      </motion.div>
    </Container>
  );
};

export default Welcome;
