// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinancialPerformanceEstimate } from "../models/onboarding/financialPerformance.model";

export type TMonthlyExpense = {
  id: number;
  text: string;
};

export type TBiggestExpense = {
  isSelected: boolean;
  title: string;
  value: string;
  amount_saved: number;
};

export interface IFinancialPerformanceData {
  revenueTarget: string;
  monthlyExpense: TMonthlyExpense;
  biggestExpensesData: TBiggestExpense[];
  reductionGoal: TMonthlyExpense;
  estimate: FinancialPerformanceEstimate | null;
}
const initialState: IFinancialPerformanceData = {
  revenueTarget: "",
  monthlyExpense: {
    id: 0,
    text: "",
  },
  biggestExpensesData: [],
  reductionGoal: {
    id: 0,
    text: "",
  },
  estimate: null,
};

const financialPerformance = createSlice({
  name: "financialPerformance",
  initialState,
  reducers: {
    setRevenueTarget(state, action: PayloadAction<string>) {
      state.revenueTarget = action.payload;
    },
    setMonthlyExpense(state, action: PayloadAction<TMonthlyExpense>) {
      state.monthlyExpense = { ...action.payload };
    },
    setBiggestExpenseData(state, action: PayloadAction<TBiggestExpense[]>) {
      state.biggestExpensesData = action.payload;
    },
    setEstimate(state, action: PayloadAction<FinancialPerformanceEstimate>) {
      state.estimate = action.payload;
    },
    setReductionGoal(state, action: PayloadAction<TMonthlyExpense>) {
      state.reductionGoal = { ...action.payload };
    },
    reset: () => initialState,
  },
});

export const financialPerformanceActions = financialPerformance.actions;
export const financialPerformanceReducer = financialPerformance.reducer;
