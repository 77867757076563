// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerSatisfactionEstimate } from "../models/onboarding/customerSatisfaction.model";

export type TSatisfactionLevel = {
  id: number;
  text: string;
};

export type TImproveLevel = {
  isSelected: boolean;
  title: string;
  value: string;
};

export interface ICustomerSatisfactionFlowInfo {
  selectedCustomerFeedbackMethods: string[];
  satisfactionLevel: TSatisfactionLevel;
  improveLevelData: string[];
  satisfactionScore: number;
  estimate: CustomerSatisfactionEstimate | null;
}

const initialState: ICustomerSatisfactionFlowInfo = {
  selectedCustomerFeedbackMethods: [],
  satisfactionLevel: {
    id: 0,
    text: "",
  },
  improveLevelData: [],
  satisfactionScore: 0,
  estimate: null,
};

const CustomerSatisfaction = createSlice({
  name: "customerSatisfaction",
  initialState,
  reducers: {
    setSelectedCustomerFeedbackMethods(state, action: PayloadAction<string[]>) {
      state.selectedCustomerFeedbackMethods = action.payload;
    },
    setSatisfactionLevel(state, action: PayloadAction<TSatisfactionLevel>) {
      state.satisfactionLevel = { ...action.payload };
    },
    setImproveLevelData(state, action: PayloadAction<string[]>) {
      state.improveLevelData = action.payload;
    },
    setEstimate(state, action: PayloadAction<CustomerSatisfactionEstimate>) {
      state.estimate = action.payload;
    },
    setSatisfactionScore(state, action: PayloadAction<number>) {
      state.satisfactionScore = action.payload;
    },
    reset: () => initialState,
  },
});

export const CustomerSatisfactionActions = CustomerSatisfaction.actions;
export const CustomerSatisfactionReducer = CustomerSatisfaction.reducer;
