import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  CurrencyType,
  RemitanceAccount,
  WalletSliceType,
} from "../../models/dashboadSettings/wallet";
import { numberWithCommas } from "../../helpers";

const initialState: WalletSliceType = {
  wallet_balance: {
    raw: 0,
    formatted: "0.00",
    formatted_with_symbol: "₦ 0.00",
    formatted_with_code: "0.00 NGN",
  },
  currencies: [
    {
      code: "NGN",
      full_name: "Nigerian Naira",
      symbol: "₦",
      exchange_rate: 690,
    },
  ],
  currency: {
    code: "NGN",
    full_name: "Nigerian Naira",
    symbol: "₦",
    exchange_rate: 690,
  },
  subscription: {
    name: "free",
    is_active: true,
    expires: "Never",
    price_per_month: {
      raw: 0,
      formatted: "0.00",
      formatted_with_code: "0.00 NGN",
      formatted_with_symbol: "₦ 0.00",
    },
  },
  transaction_history: [],
  remitance_account: null,
};

const wallet = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet(state, action: PayloadAction<WalletSliceType>) {
      state = action.payload;
    },
    setBalance(state, action: PayloadAction<any>) {
      state.wallet_balance = action.payload;
    },
    setTransactions(state, action: PayloadAction<any>) {
      state.transaction_history = action.payload;
    },
    setSubscription(state, action: PayloadAction<any>) {
      state.subscription = action.payload;
    },
    setDefaultCurrency(state, action: PayloadAction<CurrencyType>) {
      const newItem = action.payload;
      const newBalance =
        state.currency.code !== "USD"
          ? state.wallet_balance.raw / state.currency.exchange_rate
          : state.wallet_balance.raw;

      const subscriptionAmount =
        state.currency.code !== "USD"
          ? state.subscription.price_per_month.raw /
            state.currency.exchange_rate
          : state.subscription.price_per_month.raw;

      if (newItem.code === "USD") {
        state.wallet_balance = {
          raw: newBalance,
          formatted: `${numberWithCommas(newBalance)}}`,
          formatted_with_symbol: `${newItem.symbol} ${numberWithCommas(
            newBalance
          )}`,
          formatted_with_code: `${numberWithCommas(newBalance)} ${
            newItem.code
          }`,
        };

        state.subscription = {
          ...state.subscription,
          price_per_month: {
            raw: subscriptionAmount,
            formatted: `${numberWithCommas(subscriptionAmount)}}`,
            formatted_with_symbol: `${newItem.symbol} ${numberWithCommas(
              subscriptionAmount
            )}`,
            formatted_with_code: `${numberWithCommas(subscriptionAmount)} ${
              newItem.code
            }`,
          },
        };
      } else {
        state.wallet_balance = {
          raw: newBalance * newItem.exchange_rate,
          formatted: `${numberWithCommas(newBalance * newItem.exchange_rate)}}`,
          formatted_with_symbol: `${newItem.symbol} ${numberWithCommas(
            newBalance * newItem.exchange_rate
          )}`,
          formatted_with_code: `${numberWithCommas(
            newBalance * newItem.exchange_rate
          )} ${newItem.code} `,
        };

        state.subscription = {
          ...state.subscription,
          price_per_month: {
            raw: subscriptionAmount * newItem.exchange_rate,
            formatted: `${numberWithCommas(
              subscriptionAmount * newItem.exchange_rate
            )}}`,
            formatted_with_symbol: `${newItem.symbol} ${numberWithCommas(
              subscriptionAmount * newItem.exchange_rate
            )}`,
            formatted_with_code: `${numberWithCommas(
              subscriptionAmount * newItem.exchange_rate
            )} ${newItem.code} `,
          },
        };
      }

      state.transaction_history = state.transaction_history.map((item) => {
        if (state.currency.code === "USD" && newItem.code === "USD")
          return item;

        const newAmount =
          state.currency.code !== "USD"
            ? item.amount.raw / state.currency.exchange_rate
            : item.amount.raw;

        if (newItem.code === "USD")
          return {
            ...item,
            amount: {
              raw: newAmount,
              formatted: `${numberWithCommas(newAmount)}`,
              formatted_with_code: `${numberWithCommas(newAmount)} ${
                newItem.code
              }`,
              formatted_with_symbol: ` ${newItem.symbol} ${numberWithCommas(
                newAmount
              )}`,
            },
          };

        return {
          ...item,
          amount: {
            raw: newAmount * newItem.exchange_rate,
            formatted: `${numberWithCommas(newAmount * newItem.exchange_rate)}`,
            formatted_with_code: `${numberWithCommas(
              newAmount * newItem.exchange_rate
            )} ${newItem.code}`,
            formatted_with_symbol: ` ${newItem.symbol} ${numberWithCommas(
              newAmount * newItem.exchange_rate
            )}`,
          },
        };
      });

      state.currency = newItem;
    },
    setRemitanceAccount(state, action: PayloadAction<RemitanceAccount>) {
      state.remitance_account = action.payload;
    },
    reset: () => initialState,
  },
});

export const walletActions = wallet.actions;
export const walletReducer = wallet.reducer;
