import { CSSProperties } from "react";

type ButtonProps = {
  className?: string;
  text: string;
  disabled?: boolean;
  loading?: boolean;
  type?: "submit" | "button" | "reset";
  onClick?: () => void;
  icon?: string;
  style?: CSSProperties;
};

const Button = ({
  className,
  text,
  disabled,
  loading,
  onClick,
  icon,
  type = "submit",
  style,
}: ButtonProps) => {
  return (
    <button
      className={className}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      style={style}
    >
      {loading ? (
        <>
          {" "}
          <i
            style={{
              marginRight: "5px",
              fontSize: "14px",
            }}
            className="fa fa-spinner fa-spin"
          ></i>{" "}
          Loading
        </>
      ) : (
        <>
          {text} {icon && <img src={icon} alt="" />}{" "}
        </>
      )}
    </button>
  );
};

export default Button;
