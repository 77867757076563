import React from "react";
import { motionsLogos } from "../../assets";
import classes from "./startScreen.module.scss";

const StartScreen = () => {
  return (
    <div className={classes.startScreen}>
      <div className={classes.logo}>
        <img src={motionsLogos.fullLogo} alt="" />
      </div>
      <div className={classes.cubes}>
        <div className={classes.container}>
          <div className={classes.cube}>
            <div className={classes.cube__inner}></div>
          </div>
          <div className={classes.cube}>
            <div className={classes.cube__inner}></div>
          </div>
          <div className={classes.cube}>
            <div className={classes.cube__inner}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
