// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VendorAuthResponse } from "@/models/vendor";
import { vendorServiceApi } from "@/services/Vendor/vendor";

const initialState: {
  vendor: VendorAuthResponse | null;
  locations: any[];
} = {
  vendor: null,
  locations: [],
};

const vendorAuth = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setAuthentication(state, action: PayloadAction<VendorAuthResponse>) {
      state.vendor = { ...action.payload };
    },
    setLocations(state, action: PayloadAction<any[]>) {
      state.locations = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      vendorServiceApi.endpoints.verifyVendorLoginOTP.matchFulfilled,
      (state, { payload }) => {
        state.vendor = { ...payload };

        localStorage.setItem(
          "motionspace_vendor_token",
          JSON.stringify(payload),
        );
      },
    );
    builder.addMatcher(
      vendorServiceApi.endpoints.editVendorAccountDetails.matchFulfilled,
      (state, { payload }) => {
        const updatedInfo = { ...state.vendor, ...payload };
        state.vendor = updatedInfo;

        localStorage.setItem(
          "motionspace_vendor_token",
          JSON.stringify(updatedInfo),
        );
      },
    );
    builder.addMatcher(
      vendorServiceApi.endpoints.retrieveLoggedInVendorDetails.matchFulfilled,
      (state, { payload }) => {
        const updatedInfo = { ...state.vendor, ...payload };
        state.vendor = updatedInfo;

        localStorage.setItem(
          "motionspace_vendor_token",
          JSON.stringify(updatedInfo),
        );
      },
    );
  },
});

export const vendorAuthActions = vendorAuth.actions;
export const vendorAuthReducer = vendorAuth.reducer;
